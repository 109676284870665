import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Divider, Grid, Typography } from "antd";
import { AppBox } from "library/components/AppBox";
import { TitledLogo } from "library/components/TitledLogo";
import BannerLandscape from "resources/images/banner-gelson-lantigua-landscape.jpg";

import classes from "./AuthCard.module.css";

interface AuthCardProps {
  text: string;
  redirectSuggestion?: string;
  redirectPath?: string;
  children: React.ReactNode;
}

export default function AuthCard(props: AuthCardProps) {
  const { t } = useTranslation();
  const { text, children, redirectSuggestion, redirectPath } = props;
  const { md: isLarge } = Grid.useBreakpoint();

  return (
    <Card
      className={classes.card}
      bordered={false}
      cover={
        isLarge ? (
          <AppBox className={classes.cardCover}>
            <TitledLogo />
            <Typography.Paragraph className={classes.cardSubtitle}>
              {text}
            </Typography.Paragraph>
          </AppBox>
        ) : (
          <img style={{ padding: 24 }} src={BannerLandscape} alt="Gelson Lantigua" />
        )
      }
    >
      {children}

      {/* <Divider orientation="center">{t("dividers.or")}</Divider> */}

      {redirectPath && redirectSuggestion && (
        <AppBox
          style={{ marginTop: 20 }}
          className={classes.redirectSuggestion}
        >
          <Link to={redirectPath}>{redirectSuggestion}</Link>
        </AppBox>
      )}
    </Card>
  );
}
