import { Mapper } from "library/common/interfaces";
import { Role } from 'library/models/Role';
import { GetRole, CreateRole } from '../dto/role-dto';
import { RoleFormSchema } from 'features/RoleCrud/RoleForm/RoleFormSchema';
import { DefaultOptionType } from "antd/lib/select";

export class RoleMapper implements Mapper<
  Role, 
  GetRole, 
  CreateRole, 
  RoleFormSchema
>
{
  formSchemaToAPI(data: RoleFormSchema): CreateRole {
    return {
      id: data.id,
      nombre: data.name,
      comentario: data.commentary,
      descripcion: data.commentary
    };
  }

  fromAPI(data: GetRole): Role {
    return {
      id: data.id,
      name: data.nombre,
      description: data.descripcion,
      commentary: data.comentario,
    };
  }

  toSelectOption(role: Role): DefaultOptionType {
    return {
      label: role.name,
      value: role.id,
    };
  }
}
