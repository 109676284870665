import { CrudStateOld } from "library/common/interfaces";
import { createCrudSlice } from "redux/sliceCreator";
import { Position } from 'library/models/Position';

export interface PositionState extends CrudStateOld<Position> {}

const initialState: PositionState = {
  results: [],
  isLoading: false,
  hasError: false,
  errorMessage: "",
};

export const positionSlice = createCrudSlice<Position, PositionState, "positions">({
  name: "positions",
  idSource: "id",
  initialState,
});

export const { ...actions } = positionSlice.actions;
