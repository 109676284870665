import React, { useEffect } from "react";
import { Form, FormItemProps, InputProps } from "antd";
import { cleanCardId } from "library/helpers/clean-card-id";
import { useMemberIdentity } from "./useMemberIdentity";
import { MaskedInput } from "antd-mask-input";
import { IdentitySource } from "library/common/types";
import { cardIdMask } from "library/common/constants";
import { useTranslation } from 'react-i18next';
import { UserIdentity } from "library/models/User";

interface MaskInputProps extends InputProps {
  value?: string;
  defaultValue?: string;
}

export interface CardIdFormItemProps extends FormItemProps {
  source: IdentitySource;
  watchValue?: boolean;
  disabled?: boolean;
  inputProps?: MaskInputProps;
  fallbackExtra?: string;
  onValidate?: (isValid: boolean, identity?: UserIdentity) => void;
  validateInitial?: string;
}

export default React.forwardRef<any, CardIdFormItemProps>((props, ref) => {
  const { t } = useTranslation();

  const {
    help = t("errors.comite_card_id_not_exists"),
    source,
    watchValue,
    inputProps,
    fallbackExtra,
    onValidate,
    disabled,
    validateInitial,
    ...formItemProps
  } = props;

  const {
    isVerifying,
    verifyIdentity,
    cancelVerification,
    isInvalid,
    identity,
  } = useMemberIdentity();

  useEffect(() => {
    if (validateInitial) {
      verifyIdentity(validateInitial, source, onValidate);
    }
  }, [validateInitial]);

  const handleChange = async (cardId: string): Promise<void> => {
    const identification = cleanCardId(cardId);

    if (identification.length === 11) {
      verifyIdentity(identification, source, onValidate);
    } else {
      cancelVerification();
    }
  };

  const extra = isInvalid
    ? help
    : isVerifying || identity
      ? identity?.fullName
      : fallbackExtra;

  const validateStatus = isVerifying
    ? "validating"
    : isInvalid
      ? "error"
      : identity
        ? "success"
        : "";

  const onInput = !disabled 
    ? (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value) 
    : undefined;
  
  return (
    <Form.Item
      validateStatus={validateStatus}
      extra={extra}
      hasFeedback
      {...formItemProps}
    >
      <MaskedInput
        size="middle"
        {...inputProps}
        disabled={isVerifying || disabled}
        mask={cardIdMask}
        // suffix={!validateStatus && <IdcardOutlined />}
        onInput={onInput}
      />
    </Form.Item>
  );
});
