import React from "react";
import { Drawer, DrawerProps } from "antd";
import { useDrawerSize } from "./useDrawerSize";
import { Breakpoint } from "antd/es/_util/responsiveObserve";

export interface AppDrawerProps extends Omit<DrawerProps, "size"> {
  onCloseEnd?: () => void;
  size?: Partial<Record<Breakpoint, number | string>>;
}

export function AppDrawer({
  onCloseEnd,
  size,
  width,
  ...drawerProps
}: AppDrawerProps) {
  const customSize = useDrawerSize({ size });

  const handleOpenChange = (open: boolean): void => {
    if (!open && onCloseEnd) onCloseEnd();
  };

  return (
    <Drawer
      width={width || customSize}
      afterOpenChange={handleOpenChange}
      forceRender
      destroyOnClose
      {...drawerProps}
    />
  );
}
