// 'Internacional','Nacional','provincial','Municipal','Distrito Municipal','Zonal','Paraje', 'Barrial'

export enum DateUnit {
  Day = "day",
  Month = "month",
  Year = "year",
}

export enum DemarcationUnit {
  Global = "global",
  Country = "pais",
  Province = "provincia",
  Constituency = "circunscripcion",
  City = "ciudad",
  District = "distrito",
  Zone = "zona",
  Precinct = "recinto",
  College = "colegio",
}

export enum DemarcationDrilldown {
  Country = "pais",
  Province = "provincia",
  City = "ciudad",
  District = "distrito",
}

export enum ContentType {
  TEXT = "text/plain",
  JPG = "image/jpg",
  PNG = "image/png",
  JPEG = "image/jpeg",
  CSV = "text/csv",
  PDF = "application/pdf",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.ms-excel",
}

export enum ScopeType {
  Barrial = "Barrial",
  Zonal = "Zonal",
  Distrital = "Distrital",
  Municipal = "Municipal",
  Circunscripcional = "Circunscripcional",
  Provincial = "Provincial",
  Nacional = "Nacional",
}

export enum PositionType {
  Nominal = "Nominal",
  Plurinominal = "Plurinominal",
}

export enum ComiteStatus {
  Initial = "Pendiente",
  Draft = "Borrador",
  Sent = "Enviado",
  Review = "Corregir",
  Validated = "Validado",
}

export enum ModuleKey {
  Roles = "roles",
  Modules = "modulos",
  Permissions = "permisos",
  Members = "militantes",
  Positions = "cargos",
  Templates = "plantillas",
  Users = "usuarios",
  Territories = "territorios",
  Comites = "estructuras-partidarias",
  ComitesRevision = "estructuras-partidarias-revision",
  Statistics = "estadisticas",
  Verify = "consulta",
  Precincts2020 = "recintos",
  Precincts2024 = "recintos_2024",
  Mails = "Correos",
  DataCollection = "recoleccion-de-datos",
  Designations = "designaciones",
  Leaders = "dirigentes",
  Growth = "crecimiento",
  Questionary = "cuestionario",
  Precandidates = "precandidatos",
  
  // Perfil
  MilitantPersonalInfo = "perfil-informacion-personal",
  MilitantContactInfo = "perfil-informacion-contacto",
  MilitantVoteInfo = "perfil-informacion-votacion",
  MilitantPartidaryInfo = "perfil-informacion-partidaria",
  MilitantSocialBenefits = "perfil-beneficios-sociales",
  MilitantSportPreferences = "perfil-preferencias-deportivas",
  
  // Comite Afectivo
  CBA = "cba",
  
  // PROSCYR
  ProscyrMilitants = "proscyr-militantes",
  ProscyrDelegates = "proscyr-delegados",
  ProscyrDesignations = "proscyr-designaciones",
  ProscyrPrecincts = "proscyr-recintos",

  
  NewSwornIn = "nuevos-juramentados",
  PrimariesExport = "exportar-primarias",
  PadronExport = "exportar-padron",
  PadronExportSocialBenefits = "export-padron-ayudas-sociales"
}

export enum Place {
  "1er",
  "2do",
  "3ro",
  "4to",
  "5to",
  "6to",
  "7mo",
  "8vo",
  "9no",
  "10mo",
  "11avo",
  "12avo",
  "13avo",
  "14avo",
  "15avo",
  "16avo",
  "17avo",
  "18avo",
  "19avo",
  "20avo",
}
