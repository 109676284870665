import { CrudStateOld } from "library/common/interfaces";
import { createCrudSlice } from "redux/sliceCreator";
import { Module } from 'library/models/Module';

export interface ModuleState extends CrudStateOld<Module> {}

const initialState: ModuleState = {
  results: [],
  isLoading: false,
  hasError: false,
  errorMessage: "",
};

export const moduleSlice = createCrudSlice<Module, ModuleState, "modules">({
  name: "modules",
  idSource: "id",
  initialState,
});

export const { ...actions } = moduleSlice.actions;
