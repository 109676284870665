import React from "react";
import { Typography, Space } from "antd";
import { ReactComponent as Logo } from "resources/images/gespol-logo.svg";

import classes from "./TitledLogo.module.css";
import { Trans } from "react-i18next";

export default function TitledLogo() {
  return (
    <Space direction="vertical" size="middle" align="center">
      <Logo className={classes.logo} />
      <Typography.Title level={4} className={classes.appTitle}>
        <Trans i18nKey="headings.app_name_long" />
      </Typography.Title>
      <Typography.Title level={2} className={classes.title}>
      <Trans i18nKey="headings.app_name" />
      </Typography.Title>
    </Space>
  );
}
