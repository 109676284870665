import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthPanel } from "features/AuthPanel";
import { Signup } from "features/Auth/Signup";
import { NotFound } from "features/NotFound";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/unete" />} />
      <Route path="unete" element={<AuthPanel />}>
        <Route index element={<Signup forCandidates />} />
      </Route>
      {/* Routes for all users */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
