import React from "react";
import { Option } from "library/common/interfaces";
import { UtilsService } from "library/api/services/UtilsService";
import { CommonUtil } from "library/models/Utils";

const mapOption = (item: CommonUtil) => ({
  value: item.code,
  label: item.name,
});

export function useFetchUtilities() {
  const utilsService = new UtilsService();

  const fetchOcupaciones = async (search?: string): Promise<Option[]> => {
    const occupations = await utilsService.getOcupaciones({
      filter: search,
    });

    return occupations.map((occup) => ({
      value: occup.code,
      label: occup.name,
    }));
  };

  const fetchNivelesAcademicos = async (search?: string): Promise<Option[]> => {
    const academicLevels = await utilsService.getNivelesAcademicos({
      filter: search,
    });

    return academicLevels.map((fuent) => ({
      value: fuent.code,
      label: fuent.name,
    }));
  };

  const fetchFuentesApoyo = async (search?: string): Promise<Option[]> => {
    const fuentesApoyo = await utilsService.getFuentesApoyo({
      filter: search,
    });

    return fuentesApoyo.map((fuent) => ({
      value: fuent.code,
      label: fuent.name,
    }));
  };

  const fetchEstadosCiviles = async (search?: string): Promise<Option[]> => {
    const estadosCiviles = await utilsService.getEstadosCiviles({
      filter: search,
    });

    return estadosCiviles.map(mapOption);
  };

  const fetchGenders = async (search?: string): Promise<Option[]> => {
    const genders = await utilsService.getGenders({
      filter: search,
    });

    return genders.map(mapOption);
  };

  const fetchFamiliarCompositions = async (
    search?: string
  ): Promise<Option[]> => {
    const familiarCompositions = await utilsService.getFamiliarCompositions({
      filter: search,
    });

    return familiarCompositions.map(mapOption);
  };

  const fetchIncomeLevels = async (search?: string): Promise<Option[]> => {
    const incomeLevels = await utilsService.getIncomeLevels({
      filter: search,
    });

    return incomeLevels.map(mapOption);
  };

  const fetchReligions = async (search?: string): Promise<Option[]> => {
    const religions = await utilsService.getReligions({
      filter: search,
    });

    return religions.map(mapOption);
  };

  const fetchBloodTypes = async (search?: string): Promise<Option[]> => {
    const bloodTypes = await utilsService.getBloodTypes({
      filter: search,
    });

    return bloodTypes.map(bloodType => ({
      label: bloodType.name,
      value: bloodType.code
    }));
  };

  const fetchCargosCampana = async (search?: string): Promise<Option[]> => {
    const cargos = await utilsService.getCargosCampana({
      filter: search,
    });

    return cargos.map(mapOption);
  };

  const fetchPartidaryOrganisms = async (
    search?: string,
    fuenteApoyoCode?: string,
  ): Promise<Option[]> => {
    const organisms = await utilsService.getPartidaryOrganism({
      codigo_fuente_apoyo: fuenteApoyoCode,
      filter: search,
    });

    return organisms.map(mapOption);
  };

  const fetchOrganismTypes = async (search?: string): Promise<Option[]> => {
    const organismTypes = await utilsService.getOrganismTypes({
      filter: search,
    });

    return organismTypes.map(mapOption);
  };

  const fetchBaseballTeams = async (search?: string): Promise<Option[]> => {
    const baseballTeams = await utilsService.getBaseballTeams({
      filter: search,
    });

    return baseballTeams.map(mapOption);
  };

  const fetchBasketballTeams = async (search?: string): Promise<Option[]> => {
    const basketballTeams = await utilsService.getBasketballTeams({
      filter: search,
    });

    return basketballTeams.map(mapOption);
  };

  const fetchSportClubs = async (search?: string): Promise<Option[]> => {
    const sportClubs = await utilsService.getSportClubs({
      filter: search,
    });

    return sportClubs.map((sportClub) => ({
      value: sportClub.code,
      label: sportClub.name,
    }));
  };

  const fetchOrganismoAsociados = async (
    fuenteApoyoCode: string,
    search?: string
  ): Promise<Option[]> => {
    const organismoAsociados = await utilsService.getOrganismoAsociados({
      codigo_fuente_apoyo: fuenteApoyoCode,
      filter: search,
    });

    return organismoAsociados.map((org) => ({
      value: org.code,
      label: org.name,
    }));
  };

  const fetchCandidacyLevels = async (
    cardId?: string,
    search?: string
  ): Promise<Option[]> => {
    const candidacyLevels = await utilsService.getCandidacyLevels({
      filter: search,
      cedula: cardId
    });

    return candidacyLevels.map((level) => ({
      value: level.code,
      label: level.name,
    }));
  };

  const fetchCandidates = async (
    candicacyLevelId: number,
    cardId: string,
    party: string,
    search?: string
  ): Promise<Option[]> => {
    const candidates = await utilsService.getCandidates({
      filter: search,
      cedula: cardId,
      partido: party,
      nivel: candicacyLevelId,
    });

    return candidates.map((candidate) => ({
      value: candidate.code,
      label: candidate.name,
    }));
  };

  return {
    fetchOcupaciones,
    fetchFuentesApoyo,
    fetchNivelesAcademicos,
    fetchOrganismoAsociados,
    fetchEstadosCiviles,
    fetchGenders,
    fetchFamiliarCompositions,
    fetchIncomeLevels,
    fetchBloodTypes,
    fetchReligions,
    fetchCargosCampana,
    fetchPartidaryOrganisms,
    fetchOrganismTypes,
    fetchBaseballTeams,
    fetchBasketballTeams,
    fetchSportClubs,
    fetchCandidacyLevels,
    fetchCandidates
  };
}
