import { Member } from "library/models/Member";
import { createCrudSlice } from "redux/sliceCreator";
import { createPaginationReducers } from '../reducers/paginationReducers';
import { PaginationCaseReducers } from 'redux/reducers/paginationReducers';
import { FilterValues, PaginationStateOld } from '../../library/common/interfaces';

export interface MemberFilterValues
  extends FilterValues<Member>,
    Partial<Member> {}

export interface MemberState extends PaginationStateOld<Member> {}

const reducers = createPaginationReducers<Member, MemberState>();

const initialState: MemberState = {
  results: [],
  total: 0,
  filtered: 0,
  page: 1,
  pageSize: 10,
  filter: {
    sortType: 'desc',
    sortBy: 'id',
    search: ''
  },
  hasError: false,
  isLoading: false,
  errorMessage: "",
};

export const memberSlice = createCrudSlice<
  Member,
  MemberState,
  "members",
  PaginationCaseReducers<Member, MemberState>
>({
  name: "members",
  idSource: "id",
  initialState,
  extend: { ...reducers },
});

export const actions = memberSlice.actions;
