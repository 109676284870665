import { createCrudSlice } from "redux/sliceCreator";
import { FilterValues } from "library/common/interfaces";
import { createPaginationReducers } from "../reducers/paginationReducers";
import { PaginationCaseReducers } from "redux/reducers/paginationReducers";
import { Leader } from "library/models/Leader";
import { PaginationStateOld } from 'library/common/interfaces';

export interface LeaderFilterValues
  extends FilterValues<Leader>,
    Partial<Leader> {}

export interface LeaderState extends PaginationStateOld<Leader> {}

const reducers = createPaginationReducers<Leader, LeaderState>();

const initialState: LeaderState = {
  results: [],
  total: 0,
  filtered: 0,
  page: 1,
  pageSize: 10,
  filter: {
    sortType: "desc",
    sortBy: "nombres",
    search: "",
  },
  hasError: false,
  isLoading: false,
  errorMessage: "",
};

export const leaderSlice = createCrudSlice<
  Leader,
  LeaderState,
  "leaders",
  PaginationCaseReducers<Leader, LeaderState>
>({
  name: "leaders",
  idSource: "cedula",
  initialState,
  extend: { ...reducers },
});

export const actions = leaderSlice.actions;
