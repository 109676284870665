import { Mapper } from "library/common/interfaces";
import { Permission } from 'library/models/Permission';
import { GetPermission, CreatePermission } from '../dto/permission-dto';
import { PermissionFormSchema } from 'features/PermissionCrud/PermissionForm/PermissionFormSchema';
import { DefaultOptionType } from "antd/lib/select";
import { RoleMapper } from './RoleMapper';
import { ModuleMapper } from './ModuleMapper';

export class PermissionMapper implements Mapper<
  Permission, 
  GetPermission, 
  CreatePermission, 
  PermissionFormSchema
>
{
  formSchemaToAPI(data: PermissionFormSchema): CreatePermission {
    return {
      id: data.id,
      id_rol: data.roleId,
      id_modulo: data.moduleId,
      ver: data.canView ? "Y" : "N",
      crear: data.canCreate ? "Y" : "N",
      editar: data.canEdit ? "Y" : "N",
      eliminar: data.canDelete ? "Y" : "N",
      exportar: "Y",
      solo_mis_datos: data.canOnlyViewOwn ? "Y" : "N",
      comentario: data.commentary
    };
  }

  fromAPI(data: GetPermission): Permission {
    const roleMapper = new RoleMapper();
    const moduleMapper = new ModuleMapper();

    return {
      id: data.id,
      roleId: data.rol_detail.id,
      moduleId: data.modulo_detail.id,
      roleName: data.rol_detail.nombre,
      moduleName: data.modulo_detail.nombre,
      moduleDescription: data.modulo_detail.descripcion,
      canView: data.ver === 'Y',
      canDelete: data.eliminar === 'Y',
      canCreate: data.crear === 'Y',
      canEdit: data.editar === 'Y',
      canOnlyViewOwn: data.solo_mis_datos === 'Y',
      commentary: data.comentario,
      role: roleMapper.fromAPI(data.rol_detail),
      module: moduleMapper.fromAPI(data.modulo_detail)
    };
  }

  toSelectOption(permission: Permission): DefaultOptionType {
    return {
      label: permission.roleId + ' - ' + permission.moduleId,
      value: permission.id
    };
  }
}
