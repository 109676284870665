import React from "react";
import { Select, SelectProps } from "antd";
import { partyNames } from "library/common/constants";

// const prospects = [
//   'PRM',
//   'PLD',
//   'PRSC',
//   'PRD',
//   'UDC',
//   'PARTIDO-VERDE',
//   'PLR',
//   'PRSD',
//   'MODA',
//   'FRENTE-AMPLIO',
//   'PQDC',
//   'PAL',
//   'AL-PAIS',
//   'BIS',
//   'PCR',
//   'PAIS-POSIBLE',
//   'APD',
//   'FNP',
//   'PPC',
//   'DXC',
//   'PDP',
//   'FUPU',
//   'MULTIPLES',
//   'SIN-AFILIACIÓN'
// ];

const prospects = [
  "PRM",
  // "PLD",
  // "PRSC",
  // "PRD",
  // "UDC",
  // "PVD",
  // "PLR",
  // "PRSD",
  // "MODA",
  // "FA",
  // "PQDC",
  // "PAL",
  // "ALPAIS",
  // "BIS",
  // "PCR",
  // "APD",
  // "FNP",
  // "PPC",
  // "DXC",
  // "PDP",
  // "FP",
  // "PDI",
  // "PPP",
  // "PNVC",
  // "MHI",
  // "PUN",
  // "PHD",
  // "GENS",
  // "OD",
];

export default function ProspectSelect(props: SelectProps) {
  const options = prospects.map((prospect) => ({
    value: prospect,
    label: `${prospect} - ${(partyNames as any)[prospect]}`,
  }));

  return (
    <Select
      showSearch
      optionFilterProp="label"
      {...props}
      options={options}
      allowClear
    />
  );
}
