import { useState } from "react";
import { UserIdentity } from "library/models/User";
import { MemberService } from "library/api/services/MemberService";
import { IdentitySource } from "library/common/types";

export function useMemberIdentity() {
  const [identity, setIdentity] = useState<UserIdentity | null>(null);
  const [isVerifying, setVerifying] = useState<boolean>(false);
  const [isInvalid, setInvalid] = useState<boolean>(false);
  const service = new MemberService();

  const verifyIdentity = async (
    identityCard: string,
    source: IdentitySource,
    callback?: (isValid: boolean, identity?: UserIdentity) => void
  ): Promise<void> => {
    setVerifying(true);

    await service
      .verifyMember(identityCard, source)
      .then((result) => {
        setIdentity(result);
        setInvalid(false);
        if (callback) callback(true, result);
      })
      .catch(() => {
        setIdentity(null);
        setInvalid(true);
        if (callback) callback(false);
      });

    setVerifying(false);
  };

  const cancelVerification = (): void => {
    setInvalid(false);
    setVerifying(false);
    setIdentity(null);
  };

  return {
    identity,
    isInvalid,
    isVerifying,
    verifyIdentity,
    cancelVerification,
  };
}
