import { Mapper } from "library/common/interfaces";
import { User } from "library/models/User";
import { UserFormSchema } from "features/UserCrud/UserForm/UserFormSchema";
import { GetUser, CreateUser, GetUserIdentity } from "../dto/user-dto";
import { UserIdentity } from "library/models/User";
import { DefaultOptionType } from "antd/lib/select";
import { cleanCardId } from '../../helpers/clean-card-id';
import { RoleMapper } from './RoleMapper';

export class UserMapper
  implements Mapper<User, GetUser, CreateUser, UserFormSchema>
{
  formSchemaToAPI(schema: UserFormSchema): CreateUser {
    return {
      id: schema.id,
      usuario: schema.username,
      clave: schema.password,
      cedula: cleanCardId(schema.cardId),
      email: schema.email,
      rol: schema.roleId,
      supervisor: schema.supervisor,
      departamento: schema.department,
      activo: schema.active ? "Y" : "N",
      habilitado: schema.accountActive ? "Y" : "N",
      cambiar_clave: schema.changePassRequired ? "Y" : "N",
      comentario: schema.commentary,
      codigo_pais: schema.countryId,
      codigo_provincia: schema.provinceId,
      codigo_ciudad: schema.cityId,
      codigo_circunscripcion: schema.constituencyId,
      codigo_circunscripcion_ext: schema.extConstituencyId,
      codigo_distrito_municipal: schema.districtId,
      codigo_region: schema.regionId,
      codigo_zona: schema.zoneId,
      codigo_seccion: schema.sectionId,
      codigo_paraje: schema.parajeId,
      codigo_recinto: schema.recintoId,
      codigo_colegio: schema.collegeId,
    };
  }

  fromAPI(data: GetUser): User {
    const roleMapper = new RoleMapper();
    
    return {
      id: data.id,
      username: data.usuario,
      cardId: data.cedula,
      firstName: data.nombres,
      lastName: data.apellidos,
      fullName: `${data.nombres} ${data.apellidos}`,
      email: data.email,
      roleId: data.rol_detail.id,
      supervisor: data.supervisor,
      department: data.departamento,
      active: data.activo === "Y",
      accountActive: data.habilitado === "Y",
      commentary: data.comentario,
      changePassRequired: data.cambiar_clave === "Y",
      role: roleMapper.fromAPI(data.rol_detail),
      countryId: data.codigo_pais,
      provinceId: data.codigo_provincia,
      cityId: data.codigo_ciudad,
      constituencyId: data.codigo_circunscripcion,
      extConstituencyId: data.codigo_circunscripcion_ext,
      districtId: data.codigo_distrito_municipal,
      regionId: data.codigo_region,
      zoneId: data.codigo_zona,
      sectionId: data.codigo_seccion,
      parajeId: data.codigo_paraje,
      recintoId: data.codigo_recinto,
      collegeId: data.codigo_colegio,
    };
  }

  toIdentity(data: GetUserIdentity): UserIdentity {
    return {
      cardId: data.cedula,
      firstName: data.nombres,
      lastName: data.apellidos,
      fullName: `${data.nombres} ${data.apellidos}`,
      canFidelize: Boolean(data.habil_fidelizacion && data.habil_fidelizacion > 0)
    };
  }

  toSelectOption(user: User): DefaultOptionType {
    return {
      label: user.fullName,
      value: user.id,
    };
  }
}
