import { cardIdMask } from "library/common/constants";
import { applyMask } from './apply-mask';

export const compareCardIds = (cardId1: string, cardId2: string): boolean => {
  return cleanCardId(cardId1) === cleanCardId(cardId2);
}

export const cleanCardId = (cardId: string): string => {
  return cardId ? cardId.trim().replaceAll("-", "").replaceAll("_", "") : '';
};

export const applyCardIdMask = (cardId: string): string => {
  const mask = cardIdMask;
  const replaceChar = "0";
  const fallbackChar = "_";

  let skipCount = 0;
  
  const cardMasked = mask.split("").map((maskChar, index) => {
      
    if (maskChar === replaceChar) {
      return cardId.charAt(index-skipCount) || fallbackChar;
    } 

    skipCount++;
    return maskChar;
  })

  return cardMasked.join('');
};
