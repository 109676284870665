import { CrudStateOld } from "library/common/interfaces";
import { createCrudSlice } from "redux/sliceCreator";
import { Role } from 'library/models/Role';

export interface RoleState extends CrudStateOld<Role> {}

const initialState: RoleState = {
  results: [],
  isLoading: false,
  hasError: false,
  errorMessage: "",
};

export const roleSlice = createCrudSlice<Role, RoleState, "roles">({
  name: "roles",
  idSource: "id",
  initialState,
});

export const { ...actions } = roleSlice.actions;
