import React, { useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Trans, useTranslation } from "react-i18next";
import { AuthCard } from "library/components/AuthCard";
import { Alert, Button, Form, Input, Space, Steps } from "antd";
import { CardIdFormItem } from "features/Proscyr/MembersMaintenance/CardIdFormItem";
import { PassageAuth } from "@passageidentity/passage-react";
import { Biometrics } from "./Biometrics";
import { DataSelect } from "library/components/DataSelect";
import { AppDrawer } from "library/components/AppDrawer";
import { cleanCardId } from "library/helpers/clean-card-id";
import { UserIdentity } from "library/models/User";
import { DebounceSelect } from "library/components/DebounceSelect";
import { useFetchUtilities } from "library/hooks/useFetchUtilities";
import { MaskedInput } from "antd-mask-input";

interface BecomeLoyalFormValues {
  cardId: string;
  loyalParty: string;
  candidacyLevelId: number;
  candidateId: number;
  phoneNumber: string;
  referredBy: string;
}

interface SignupProps {
  forCandidates?: boolean;
}

export default function Signup({ forCandidates }: SignupProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm<BecomeLoyalFormValues>();
  const [identity, setIdentity] = useState<UserIdentity | null>(null);
  // const [current, setCurrent] = useState(0);
  const [fidelizationValues, setFidelizationValues] =
    useState<BecomeLoyalFormValues | null>(null);

  const { fetchCandidates, fetchCandidacyLevels } = useFetchUtilities();

  const cardId = Form.useWatch("cardId", form);

  const canFidelize = !cleanCardId(cardId) || identity?.canFidelize;
  const isCardIdValid =
    cleanCardId(cardId || "").length === 11 &&
    Boolean(identity) &&
    identity?.canFidelize;

  // const prevStep = () => {
  //   if (current <= 0) return;

  //   setCurrent((prev) => prev - 1);
  // };

  // const nextStep = () => {
  //   setCurrent((prev) => prev + 1);
  // };

  // const navigation = (
  //   <Space>
  //     <Button onClick={prevStep}>Anterior</Button>
  //     <Button onClick={nextStep}>Siguiente</Button>
  //   </Space>
  // );

  const shouldUpdateProspects = (
    prev: BecomeLoyalFormValues,
    current: BecomeLoyalFormValues
  ) => {
    return prev.cardId !== current.cardId;
  };

  const startFidelization = async () => {
    const { phoneNumber, ...values } = await form.validateFields();
    const cleanedPhoneNumber = phoneNumber
      ?.replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
 
    setFidelizationValues({
      ...values,
      phoneNumber: cleanedPhoneNumber,
      cardId: cleanCardId(cardId),
    });
  };

  const restartFidelization = async () => {
    setFidelizationValues(null);
    form.resetFields();
  };

  const partyRules = [
    {
      required: true,
      message: t("rules.required", {
        field: t("labels.party"),
      }),
    },
  ];

  const candidacyLevelRules = [
    {
      required: true,
      message: t("rules.required", {
        field: t("labels.candidacy_level"),
      }),
    },
  ];

  const candidateRules = [
    {
      required: true,
      message: t("rules.required", {
        field: t("labels.candidate"),
      }),
    },
  ];

  const initialStatusProps = !cardId
    ? { extra: undefined, validateStatus: "" }
    : ({} as any);

  const cantFidelizeProps = !canFidelize
    ? { extra: t("errors.cant_fidelize"), validateStatus: "error" }
    : ({} as any);

  return (
    <AuthCard
      text={t("texts.become_loyal")}
      // redirectSuggestion={t("links.register_now")}
      // redirectPath={paths.auth.signup.fullPath}
    >
      <Form
        form={form}
        size="large"
        layout="vertical"
        onValuesChange={(changedValues) => {
          const cardId = cleanCardId(changedValues.cardId || "");

          if (Boolean(cardId) && cardId.length < 11) {
            form.resetFields([
              "loyalParty",
              "candidacyLevelId",
              "candidateId",
              "phoneNumber",
              "referredBy",
            ]);
          }
        }}
      >
        <CardIdFormItem
          name="cardId"
          label={t("labels.card_id")}
          source="jce"
          inputProps={{ size: "large", type: "tel" }}
          help={t("errors.citizen_not_found")}
          onValidate={(_, identity) => {
            setIdentity(identity || null);
          }}
          required
          {...initialStatusProps}
          {...cantFidelizeProps}
        />

        <Form.Item
          name="loyalParty"
          label={t("labels.party")}
          rules={partyRules}
          style={{ marginBottom: 30 }}
          initialValue="PRM"
        >
          <DataSelect.Prospects
            disabled={!isCardIdValid}
            placeholder={t("placeholders.select_party")}
          />
          {/* <select
            style={{
              padding: "10px 16px",
              border: "1px solid #d9d9d9",
              width: "100%",
            }}
            disabled={!isCardIdValid}
            name="loyalParty"
          >
            <option value="PRM">PRM - Partido Revolucionario Moderno</option>
          </select> */}
        </Form.Item>

        {forCandidates && (
          <React.Fragment>
            <Form.Item
              noStyle
              shouldUpdate={(prev, current) =>
                prev.loyalParty !== current.loyalParty
              }
            >
              {({ getFieldValue }) =>
                (Boolean(getFieldValue("loyalParty")) && isCardIdValid) ? (
                  <Form.Item
                    preserve={false}
                    name="candidacyLevelId"
                    label={t("labels.candicacy_level")}
                    rules={candidacyLevelRules}
                    style={{ marginBottom: 30 }}
                  >
                    <DebounceSelect
                      fetchOptions={(search) =>
                        fetchCandidacyLevels(getFieldValue("cardId"), search)
                      }
                      placeholder={t("placeholders.select_candidacy_level")}
                      suffixIcon={
                        <CloseCircleFilled style={{ color: "red" }} />
                      }
                      allowClear
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prev, current) =>
                prev.candidacyLevelId !== current.candidacyLevelId
              }
            >
              {({ getFieldValue }) =>
                Boolean(getFieldValue("candidacyLevelId")) ? (
                  <Form.Item
                    preserve={false}
                    name="candidateId"
                    label={t("labels.candidate")}
                    rules={candidateRules}
                    style={{ marginBottom: 30 }}
                  >
                    <DebounceSelect
                      fetchOptions={(search) =>
                        fetchCandidates(
                          getFieldValue("candidacyLevelId"),
                          getFieldValue("cardId"),
                          getFieldValue("loyalParty"),
                          search
                        )
                      }
                      placeholder={t("placeholders.select_candidate")}
                      noResults={t("empty.candidates_by_demarcation")}
                      suffixIcon={
                        <CloseCircleFilled style={{ color: "red" }} />
                      }
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
  
            <Form.Item
              name="phoneNumber"
              label={t("labels.phone_number")}
              style={{ marginBottom: 30 }}
            >
              <MaskedInput
                type="tel"
                size="large"
                mask="(000) 000-0000"
                disabled={!isCardIdValid}
              />
            </Form.Item>

            <Form.Item
              name="referredBy"
              label={t("labels.referred_by")}
              style={{ marginBottom: 30 }}
            >
              <Input
                type="tel"
                disabled={!isCardIdValid}
                placeholder={t("placeholders.referred_by")}
              />
            </Form.Item>
          </React.Fragment>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isCardIdValid}
            onClick={startFidelization}
            // loading={isLoading}
            block
          >
            {t("buttons.continue")}
          </Button>
        </Form.Item>
      </Form>

      <AppDrawer
        open={Boolean(fidelizationValues?.cardId)}
        title={identity?.fullName}
        onClose={restartFidelization}
        // footer={
        //   <Button block size="large" danger onClick={restartFidelization}>
        //     <Trans i18nKey="buttons.close" />
        //   </Button>
        // }
      >
        {fidelizationValues ? (
          <Biometrics
            cardId={fidelizationValues.cardId}
            loyalParty={fidelizationValues.loyalParty}
            candidacyLevelId={fidelizationValues.candidacyLevelId}
            candidateId={fidelizationValues.candidateId}
            phoneNumber={fidelizationValues.phoneNumber}
            referredBy={fidelizationValues.referredBy}
            onFinish={restartFidelization}
            onSuccess={() => {}}
          />
        ) : null}
      </AppDrawer>

      {/* <Steps direction="vertical" current={current}>
        <Steps.Step
          title={t("headings.card_id_verification")}
          description={
            current === 0 ? (
              <React.Fragment>
                <CardIdFormItem
                  source="jce"
                  help={t("errors.citizen_not_found")}
                  onValidate={(isValid, identity) => {
                    if (isValid) {
                      setCardId(identity?.cardId!);
                    } else {
                      setCardId("");
                    }
                  }}
                />
                <Button
                  disabled={!cardId}
                  onClick={cardId ? nextStep : undefined}
                >
                  Siguiente
                </Button>
              </React.Fragment>
            ) : null
          }
        />
        <Steps.Step
          title="Validar datos biométricos"
          description={
            current === 1 && cardId ? (
              <React.Fragment>

                <Biometrics cardId={cardId} onSuccess={nextStep} />
              </React.Fragment>
            ) : null
          }
        />
        <Steps.Step
          title="Validación completada"
          description={
            current === 2 && cardId ? (
              <Alert
                style={{ marginBottom: 10 }}
                showIcon
                type="success"
                description="Su rostro coincide con la foto de la cédula"
                message="Validación completada"
              />
            ) : null
          }
        />
      </Steps> */}
    </AuthCard>
  );
}
