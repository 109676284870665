import { AbstractCrudService } from "./AbstractCrudService";
import { MemberMapper } from "library/api/mappers/MemberMapper";
import { MemberFormSchema } from "features/MemberList/MemberForm/MemberFormSchema";
import { Member, MemberDetail } from "library/models/Member";
import { PaginateRepository } from "./AbstractListService";
import { FilterValues, Pagination } from "library/common/interfaces";
import * as dto from "../dto/member-dto";
import { UserIdentity } from "library/models/User";
import { GetUserIdentity } from "../dto/user-dto";
import { UserMapper } from "../mappers/UserMapper";
import { IdentitySource } from "library/common/types";
import { APIResponse } from "library/common/interfaces";
import { GetPersonalInfo } from "data/dto/personal-info-dto";

export interface MemberRepository extends PaginateRepository<Member> {
  getMember: (cardId: string) => Promise<MemberDetail>;
  verifyMember: (
    cardId: string,
    source: IdentitySource
  ) => Promise<UserIdentity>;
  getPhoto: (cardId: string) => Promise<Blob>;
}

export class MemberService
  extends AbstractCrudService<
    Member,
    dto.GetMember,
    dto.CreateMember,
    MemberFormSchema
  >
  implements MemberRepository
{
  mapper: MemberMapper;
  getAllUrl: string;
  createUrl: string;

  constructor() {
    super();
    this.mapper = new MemberMapper();
    this.getAllUrl = "/militantes/get";
    this.createUrl = "/militantes/save";
  }

  getDetailUrl(id: number): string {
    return "/militantes/get?cedula" + id.toString();
  }

  getEditUrl(): string {
    return "/militantes/save";
  }

  getDeleteUrl(id: number): string {
    return `/militantes/delete?id=${id}`;
  }

  getPhotoUrl(cardId: string): string {
    return `${process.env.REACT_APP_API_URL}/militantes/foto?c=${cardId}&t=img`;
  }

  async getProfilePicture(cardId: string): Promise<string> {
    const res = await this.client.get(`/militantes/foto?c=${cardId}`);
    return res.data || "";
  }

  paginate(
    page: number,
    pageSize: number,
    values: FilterValues<Member>
  ): Promise<Pagination<Member>> {
    return new Promise((resolve, reject) => {
      const url = "/militantes/data/full";
      const params = this.mapper.fromFilterToQueryParams(values as any);

      this.client
        .get<dto.GetPaginatedMember>(url, {
          params: {
            pagezise: pageSize,
            page,
            ...params,
          },
        })
        .then((res) => {
          const pagination = this.mapper.fromAPIPaginated(res.data);
          resolve(pagination);
        })
        // .catch(() => reject("No se pudo cargar las evaluaciones"));
        .catch(() =>
          resolve({
            page: 1,
            pageSize,
            results: [],
            total: 0,
            filtered: 0,
          })
        );
    });
  }

  // verifyMember(identityCard: string, source: IdentitySource): Promise<UserIdentity> {
  //   return new Promise((resolve, reject) => {
  //     const url = `/militantes/militantes_verifica/get?cedula=${identityCard}`;

  //     this.client.get<GetIdentityPath>(url)
  //       .then(res => {
  //         const identityPath = res.data;

  //         const sourceName = source === "members" ? "gespol" : "padron";
  //         const identity = identityPath[sourceName];

  //         if (identity.cedula) {
  //           const userMapper = new UserMapper();
  //           resolve(userMapper.toIdentity(identity));

  //         } else {
  //           reject("alerts.identity_card_not_found")
  //         }
  //       })
  //       .catch(() => reject("alerts.identity_card_not_found"))
  //   })
  // }

  getMember(cardId: string): Promise<MemberDetail> {
    return new Promise((resolve, reject) => {
      const url = `/militantes/prm/detail/get?cedula=${cardId}`;

      this.client
        .get<APIResponse<dto.GetMemberDetail[]>>(url)
        .then((res) => {
          const foundMembers = res.data.data;

          if (foundMembers.length > 0) {
            const memberDetail = this.mapper.fromAPIDetailed(foundMembers[0]);
            resolve(memberDetail);
          } else {
            reject("alerts.member_not_found");
          }
        })
        .catch(() => reject("alerts.member_not_found"));
    });
  }

  verifyMember(
    identityCard: string,
    source: IdentitySource
  ): Promise<UserIdentity> {
    return new Promise((resolve, reject) => {
      if (source === "militants") {
        this.client
          .get<APIResponse<GetPersonalInfo>>(
            `/militantes/data/personal/${identityCard}`
          )
          .then((res) => {
            const personalInfo = res.data.data;

            if (personalInfo.cedula) {
              resolve({
                cardId: personalInfo.cedula,
                firstName: personalInfo.nombres,
                lastName: personalInfo.apellidos,
                fullName: `${personalInfo.nombres} ${personalInfo.apellidos}`,
                isMilitant: personalInfo.militante === "Y",
              });
            } else {
              reject("alerts.identity_card_not_found");
            }
          })
          .catch(() => reject("alerts.identity_card_not_found"));
      } else if (source === "jce") {
        this.client
          .get<APIResponse<GetUserIdentity>>(
            `/padron/data/basica/get/${identityCard}`
          )
          .then((res) => {
            const identity = res.data.data;

            if (identity.cedula) {
              const userMapper = new UserMapper();
              resolve(userMapper.toIdentity(identity));
            } else {
              reject("alerts.identity_card_not_found");
            }
          });
      } else {
        this.client
          .get<APIResponse<GetUserIdentity>>(
            `/militantes/prm/get?cedula=${identityCard}`
          )
          .then((res) => {
            const identity = res.data.data;

            if (identity.cedula) {
              const userMapper = new UserMapper();
              resolve(userMapper.toIdentity(identity));
            } else {
              reject("alerts.identity_card_not_found");
            }
          })
          .catch(() => reject("alerts.identity_card_not_found"));
      }
    });
  }

  getPhoto(cardId: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/militantes/foto?c=${cardId}&t=img`;

      this.client
        .get<Blob>(url)
        .then((res) => resolve(res.data))
        .catch(() => reject());
    });
  }
}
