import { AbstractAPIService } from "./AbstractApiService";
import { GetAuthUser, GetToken, GetTokenUser } from "library/api/dto/auth-dto";
import { Token, TokenUser } from "library/models/Token";
import { getText } from "i18n";
import { paths, keys } from "library/common/constants";
import { AuthUser } from "library/models/User";
import { AuthMapper } from "../mappers/AuthMapper";
import { FaceCheckResult } from "./FaceLivenessService";
import { cleanCardId } from "library/helpers/clean-card-id";
import { APIResponse } from "../../common/interfaces";

type LoginResult = { token: Token; isConfirmed: boolean };

export interface AuthRepository {
  createToken: (username: string, password: string) => Promise<LoginResult>;
  getAuthUser: (token: string) => Promise<AuthUser>;
  confirmEmail: (userId: string, confirmToken: string) => Promise<TokenUser>;
  sendConfirmLink: (userId: string) => Promise<void>;
  sendResetLink: (email: string) => Promise<void>;
  validateResetToken: (token: string, tokenId: number) => Promise<void>;
  validateBiometrics: (cardId: string, sessionId: string) => Promise<{}>;
  resetPassword: (
    password: string,
    resetToken: string,
    tokenId: number
  ) => Promise<void>;
}

export class AuthService extends AbstractAPIService implements AuthRepository {
  private mapper!: AuthMapper;

  constructor() {
    super();
    this.mapper = new AuthMapper();
  }

  private get resetLink(): string {
    return (
      process.env.REACT_APP_BASE_URL +
      paths.auth.passwordReset.fullPath +
      `?${keys.linkTokenParam}=`
    );
  }

  private get confirmationLink(): string {
    return (
      process.env.REACT_APP_BASE_URL +
      paths.auth.confirmEmail.fullPath +
      `?${keys.linkTokenParam}=`
    );
  }

  createToken(username: string, password: string): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      const body = { username, userpass: password };
      this.client
        .post<GetToken>("/usuarios/gespol/auth", body)
        .then((res) => {
          if (res.data.status === "account_not_confirmed") {
            resolve({ isConfirmed: false, token: "" });
          } else if (res.data.error) {
            reject(res.data.msg);
          }

          const result: LoginResult = {
            token: res.data.tkbase64,
            isConfirmed: true,
          };
          resolve(result);
        })
        .catch(() => reject(getText("alerts.login_failed")));
    });
  }

  confirmEmail(userId: string, token: string): Promise<TokenUser> {
    return new Promise((resolve, reject) => {
      this.client
        .post<GetTokenUser>("usuarios/gespol/validar", {
          v: token,
          t: userId,
        })
        .then((res) => {
          resolve({
            token: res.data.v,
            userId: Number(res.data.t),
          });
        })
        .catch(() => reject(getText("alerts.confirm_email_failed")));
    });
  }

  createTokenWithFaceAuth(
    cardId: string,
    sessionId: string,
  ): Promise<Token> {
    return new Promise((resolve, reject) => {
      this.client
        .post<GetToken>("/usuarios/gespol/auth/no/clave/face", {
          cedula: cleanCardId(cardId),
          sessionId,
        })
        .then((res) => {
          if (res.data.error) {
            reject(res.data.msg);
          }

          resolve(res.data.tkbase64);
        })
        .catch(() => reject(getText("alerts.update_and_confirm_failed")));
    });
  }

  updatePasswordAndConfirm(
    cardId: string,
    password: string,
    sessionId: string
  ): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      this.client
        .post<GetToken>("/usuarios/gespol/auth/face", {
          sessionId,
          clave_nueva: password,
          cedula: cleanCardId(cardId),
        })
        .then((res) => {
          if (res.data.error) {
            reject(res.data.msg);
          }

          resolve({ token: res.data.tkbase64, isConfirmed: true });
        })
        .catch(() => reject(getText("alerts.update_and_confirm_failed")));
    });
  }

  sendConfirmLink(userId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const body = { userId, urlBase: this.confirmationLink };

      this.client
        .post("/restSendEmail", body)
        .then(() => resolve())
        .catch(() => reject(getText("alerts.send_confirmation_mail_failed")));
    });
  }

  getAuthUser(token: string): Promise<AuthUser> {
    return new Promise((resolve, reject) => {
      this.client
        .get<GetAuthUser>(
          "/usuarios/gespol/mydata/get",
          {
            headers: {
              'Authorization': token
            }
          }
        )
        .then((res) => {
          const user = this.mapper.userFromAPI(res.data);
          resolve(user);
        })
        .catch(() => reject(getText("alerts.get_auth_user_failed")));
    });
  }

  sendResetLink(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const body = { usuario: email };

      this.client
        .post("/usuarios/gespol/olvide_clave", body)
        .then(() => resolve())
        .catch(() => reject(getText("alerts.send_reset_mail_failed")));
    });
  }

  resetPassword(
    password: string,
    token: string,
    tokenId: number
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client
        .post("/usuarios/gespol/cambiar_clave_olvidada/save", {
          clave_nueva: password,
          v: token,
          t: tokenId,
        })
        .then(() => resolve())
        .catch(() => reject(getText("alerts.reset_password_failed")));
    });
  }

  validateResetToken(token: string, tokenId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client
        .post("/usuarios/gespol/olvide_clave_verificar", {
          v: token,
          t: tokenId,
        })
        .then(() => resolve())
        .catch(() => reject(getText("alerts.reset_link_invalid")));
    });
  }

  validateBiometrics(
    cardId: string,
    sessionId: string
  ): Promise<FaceCheckResult> {
    return new Promise((resolve, reject) => {
      this.client
        .get<FaceCheckResult>("/aws/validate/cedula/face/get", {
          params: {
            sessionId,
            cedula: cleanCardId(cardId),
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => reject(getText("alerts.get_session_failed")));
    });
  }
}
