import MemberSelect from "./MemberSelect";
import ProspectSelect from "./ProspectSelect";
import PrecandidatePositionSelect from "./PrecandidatePositionSelect";
import CivilStatusSelect from "./CivilStatusSelect";
import GenderSelect from "./GenderSelect";
import FamiliarCompositionSelect from "./FamiliarCompositionSelect";
import IncomeLevelSelect from "./IncomeLevelSelect";
import ReligionSelect from "./ReligionSelect";
import BloodTypeSelect from "./BloodTypeSelect";
import PartidaryOrganismSelect from "./PartidaryOrganismSelect";
import OrganismTypeSelect from "./OrganismTypeSelect";
import BaseballTeamSelect from "./BaseballTeamSelect";
import BasketballTeamSelect from "./BasketballTeamSelect";
import SportClubSelect from "./SportClubSelect";
import CandidacyLevelSelect from "./CandidacyLevelSelect";

const DataSelect = {
  Members: MemberSelect,
  Prospects: ProspectSelect,
  PrecandidatePositions: PrecandidatePositionSelect,
  CivilStatuses: CivilStatusSelect,
  Genders: GenderSelect,
  Religions: ReligionSelect,
  FamiliarCompositions: FamiliarCompositionSelect,
  IncomeLevels: IncomeLevelSelect,
  BloodTypes: BloodTypeSelect,
  PartidaryOrganisms: PartidaryOrganismSelect,
  OrganismTypes: OrganismTypeSelect,
  BaseballTeams: BaseballTeamSelect,
  BasketballTeam: BasketballTeamSelect,
  SportClubs: SportClubSelect,
  CandidacyLevels: CandidacyLevelSelect
}

export { DataSelect };