import { CrudStateOld } from "library/common/interfaces";
import { createCrudSlice } from "redux/sliceCreator";
import { Permission } from 'library/models/Permission';

export interface PermissionState extends CrudStateOld<Permission> {}

const initialState: PermissionState = {
  results: [],
  isLoading: false,
  hasError: false,
  errorMessage: "",
};

export const permissionSlice = createCrudSlice<Permission, PermissionState, "permissions">({
  name: "permissions",
  idSource: "id",
  initialState,
});

export const { ...actions } = permissionSlice.actions;
