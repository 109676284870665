import { Mapper } from "library/common/interfaces";
import { Module } from 'library/models/Module';
import { GetModule, CreateModule } from '../dto/module-dto';
import { ModuleFormSchema } from 'features/ModuleCrud/ModuleForm/ModuleFormSchema';
import { DefaultOptionType } from "antd/lib/select";

export class ModuleMapper implements Mapper<
  Module, 
  GetModule, 
  CreateModule, 
  ModuleFormSchema
>
{
  formSchemaToAPI(data: ModuleFormSchema): CreateModule {
    return {
      id: data.id,
      mkey: data.modKey,
      nombre: data.name,
      comentario: data.commentary,
      descripcion: data.commentary
    };
  }

  fromAPI(data: GetModule): Module {
    return {
      id: data.id,
      modKey: data.mkey,
      name: data.nombre,
      description: data.descripcion,
      commentary: data.comentario,
    };
  }

  toSelectOption(module: Module): DefaultOptionType {
    return {
      label: module.name,
      value: module.id,
    };
  }
}
