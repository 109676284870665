import React, { useMemo } from "react";
import { Grid } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";

const breakpointConfig = {
  xs: ["xs"],
  sm: ["xs", "sm"],
  md: ["xs", "sm", "md"],
  lg: ["xs", "sm", "md", "lg"],
  xl: ["xs", "sm", "md", "lg", "xl"],
  xxl: ["xs", "sm", "md", "lg", "xl", "xxl"],
};

type Options = {
  size?: Partial<Record<Breakpoint, number | string>>;
};

export function useDrawerSize({ size = {} }: Options) {
  const breakpoints = Grid.useBreakpoint();

  const currentSize = useMemo(() => {
    const currentBreakpoint = Object.keys(breakpoints)
      .filter((bp) => Boolean(breakpoints[bp as Breakpoint]))
      .pop() as Breakpoint;

    const breakOptions = breakpointConfig[currentBreakpoint] || [];

    const providedBreakpoints = breakOptions.filter((breadkpoint) =>
      Object.keys(size).includes(breadkpoint)
    ) as Breakpoint[];

    if (providedBreakpoints.length > 0) {
      const breakpoint = providedBreakpoints[providedBreakpoints.length - 1];
      return size[breakpoint];
    }

    return breakpoints.md ? 500 : "100%";
  }, [breakpoints, size]);

  return currentSize;
}
