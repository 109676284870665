import * as dto from "../dto/utils-dto";
import * as models from "library/models/Utils";
import { cleanCardId, applyCardIdMask } from "library/helpers/clean-card-id";
import moment from "moment";

export class UtilsMapper {
  fuenteApoyoFromAPI(data: dto.GetFuenteApoyo): models.FuenteApoyo {
    return {
      name: data.descripcion,
      code: data.codigo_fuente_apoyo,
      // status: data.estado,
    };
  }

  organismoAsociadoFromAPI(
    data: dto.GetOrganismoAsociado
  ): models.OrganismoAsociado {
    return {
      name: data.descripcion,
      code: data.codigo_organismo_asociado,
      fuenteApoyo: data.codigo_fuente_apoyo,
      // status: data.estado,
    };
  }
  
  nivelAcademicoFromAPI(data: dto.GetNivelAcademico): models.NivelAcademico {
    return {
      name: data.descripcion,
      code: data.codigo_nivel_academico,
      // status: data.estado,
    };
  }


  ocupacionFromAPI(data: dto.GetOcupacion): models.Ocupacion {
    return {
      name: data.descripcion,
      code: data.codigo_ocupacion,
      // status: data.estado,
    };
  }

  estadoCivilFromAPI(data: dto.GetEstadoCivil): models.EstadoCivil {
    return {
      name: data.descripcion,
      code: data.estado_civil
    };
  }

  genderFromAPI(data: dto.GetGender): models.Gender {
    return {
      name: data.descripcion,
      code: data.codigo_sexo,
    };
  }

  familiarCompositionFromAPI(data: dto.GetFamiliarComposition): models.FamiliarComposition {
    return {
      name: data.descripcion,
      code: data.codigo_composicion_familiar,
      // status: data.estado
    };
  }

  incomeLevelFromAPI(data: dto.GetIncomeLevel): models.IncomeLevel {
    return {
      name: data.descripcion,
      code: data.codigo_nivel_ingreso,
      // status: data.estado
    };
  }

  religionFromAPI(data: dto.GetReligion): models.Religion {
    return {
      name: data.descripcion,
      code: data.codigo_religion,
      // status: data.estado
    };
  }

  bloodTypeFromAPI(data: dto.GetBloodType): models.BloodType {
    return {
      name: data.descripcion,
      code: data.codigo_tipo_sangre,
      // status: data.estado
    };
  }

  partidaryOrganismFromAPI(data: dto.GetPartidaryOrganism): models.PartidaryOrganism {
    return {
      name: data.descripcion,
      code: data.codigo_organismo_partidario,
      // status: data.estado
    };
  }

  organismTypeFromAPI(data: dto.GetOrganismType): models.OrganismType {
    return {
      name: data.descripcion,
      code: data.codigo_tipo_organismo,
      // status: data.estado
    };
  }

  baseballTeamFromAPI(data: dto.GetBaseballTeam): models.BaseballTeam {
    return {
      name: data.descripcion,
      code: data.codigo_baseball,
      // status: data.estado
    };
  }

  basketballTeamFromAPI(data: dto.GetBasketballTeam): models.BasketballTeam {
    return {
      name: data.descripcion,
      code: data.codigo_basketball,
      // status: data.estado
    };
  }

  cargoCampanaFromAPI(data: dto.GetCargoCampana): models.CargoCampana {
    return {
      name: data.descripcion,
      code: data.codigo_cargo_campana,
      // status: data.estado
    };
  }

  sportClubFromAPI(data: dto.GetSportClub): models.SportClub {
    return {
      name: data.descripcion,
      code: data.codigo_club_deportivos,
      // status: data.estado
    };
  }

  candidacyLevelFromAPI(data: dto.GetCandidacyLevel): models.SportClub {
    return {
      code: data.id,
      name: data.descripcion,
    };
  }
  
  candidateFromAPI(data: dto.GetCandidate): models.Candicate {
    return {
      name: `${data.candidato_posicion} - ${data.candidato_nombre}`,
      code: data.id,
    };
  }

  citizenFromAPI(data: dto.GetCitizen): models.Citizen {
    return {
      cardId: applyCardIdMask(data.cedula),
      firstName: data.nombres,
      lastName: data.apellidos,
      genre: data.sexo,
      age: data.edad,
      partido: data.resultado,
      tooltip: data.resultado_toltip,
      photoUrl: `${
        process.env.REACT_APP_API_URL
      }/militantes/foto?c=${cleanCardId(data.cedula)}&t=img`,
      jobs: data.laboral
        ? data.laboral.map((job) => ({
            lastPayment: moment(job.fecha).format("MMM Y"),
            salary: String(job.salario).replace(/(.)(?=(\d{3})+$)/g,'$1,'),
            organization: job.institucion,
            position: job.funcion,
          }))
        : [],
    };
  }
}
