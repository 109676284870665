import React from 'react'
import { Spin, Typography } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { AppBox } from '../AppBox';

import classes from './AppLoader.module.css';

interface AppLoaderProps {
  text?: string;
  size?: "default" | "small" | "large";
}

export default function AppLoader({ text, size = "large" }: AppLoaderProps) {
  const antIcon = <LoadingOutlined spin />;

  return (
    <AppBox className={classes.center}>
      <Spin indicator={antIcon} size={size} />
      {text && <Typography.Text>{text}</Typography.Text>}
    </AppBox>
  )
}
