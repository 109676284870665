export type HintDisplayText = {
  hintMoveFaceFrontOfCameraText?: string;
  hintTooManyFacesText?: string;
  hintFaceDetectedText?: string;
  hintCanNotIdentifyText?: string;
  hintTooCloseText?: string;
  hintTooFarText?: string;
  hintConnectingText?: string;
  hintVerifyingText?: string;
  hintIlluminationTooBrightText?: string;
  hintIlluminationTooDarkText?: string;
  hintIlluminationNormalText?: string;
  hintHoldFaceForFreshnessText?: string;
  hintCenterFaceText?: string;
};

export type CameraDisplayText = {
  cameraMinSpecificationsHeadingText?: string;
  cameraMinSpecificationsMessageText?: string;
  cameraNotFoundHeadingText?: string;
  cameraNotFoundMessageText?: string;
  retryCameraPermissionsText?: string;
  waitingCameraPermissionText?: string;
};

export type InstructionDisplayText = {
  photosensitivyWarningHeadingText?: string;
  photosensitivyWarningBodyText?: string;
  photosensitivyWarningInfoText?: string;
  goodFitCaptionText?: string;
  goodFitAltText?: string;
  tooFarCaptionText?: string;
  tooFarAltText?: string;
  startScreenBeginCheckText?: string;
};

export type StreamDisplayText = {
  recordingIndicatorText?: string;
  cancelLivenessCheckText?: string;
};

export const defaultErrorDisplayText = {
  timeoutHeaderText: "Tiempo agotado",
  timeoutMessageText:
    "La cara no se ajusto dentro del óvalo en el tiempo límite. Inténtalo de nuevo y rellena completamente el óvalo con la cara dentro.",
  faceDistanceHeaderText: "Movimiento de avance detectado",
  faceDistanceMessageText: "Evita moverte cuando esta conectando",
  multipleFacesHeaderText: "Varias caras detectadas",
  multipleFacesMessageText:
    "Asegúrese de que sólo haya una cara delante de la cámara cuando se conecte.",
  clientHeaderText: "Error del cliente",
  clientMessageText: "Error de comprobación debido a un problema del cliente",
  serverHeaderText: "Error del servidor",
  serverMessageText:
    "No se puede completar la comprobación debido a un problema del servidor",
  landscapeHeaderText: "No se admite la orientación horizontal",
  landscapeMessageText: "Gire el dispositivo a la orientación vertical.",
  portraitMessageText: "Asegúrese de que su dispositivo permanezca en orientación vertical durante la duración del control.",
  tryAgainText: "Inténtelo de nuevo",
  tryagaintext: "Inténtelo de nuevo",
  tryAgaintext: "Inténtelo de nuevo",
  tryagainText: "Inténtelo de nuevo",
};

export type ErrorDisplayTextFoo = typeof defaultErrorDisplayText;
export type ErrorDisplayText = Partial<ErrorDisplayTextFoo>;

export const defaultLivenessDisplayText: Required<LivenessDisplayText> = {
  photosensitivyWarningHeadingText: "Advertencia de fotosensibilidad",
  photosensitivyWarningBodyText:
    "Esta verificación muestra luces de colores. Tenga cuidado si es fotosensible.",
  goodFitCaptionText: "Buen ajuste",
  tooFarCaptionText: "Demasiado lejos",
  hintCenterFaceText: "Centra tu cara y presiona el botón para verificarte.",
  startScreenBeginCheckText: "Presiona aquí para verificarte",
  photosensitivyWarningInfoText:
    "Algunas personas pueden sufrir ataques epilépticos cuando se exponen a luces de colores. Tenga precaución si usted, o alguien de su familia, padece epilepsia.",
  goodFitAltText:
    "Ilustración de la cara de una persona, que encaja perfectamente en el interior de un óvalo.",

  tooFarAltText:
    "Ilustración de la cara de una persona dentro de un óvalo; hay un espacio entre el perímetro de la cara y los límites del óvalo.",
  cameraMinSpecificationsHeadingText:
    "La cámara no cumple las especificaciones mínimas",
  cameraMinSpecificationsMessageText:
    "La cámara debe tener una resolución mínima de 320*240 y 15 fotogramas por segundo.",
  cameraNotFoundHeadingText: "No se puede acceder a la cámara.",
  cameraNotFoundMessageText:
    "Compruebe que hay una cámara conectada y que no hay otra aplicación utilizando la cámara. Es posible que tengas que ir a la configuración para conceder permisos a la cámara y cerrar todas las instancias del navegador y volver a intentarlo.",
  retryCameraPermissionsText: "Reintentar",
  waitingCameraPermissionText: "Esperando a que permita el permiso de cámara.",
  cancelLivenessCheckText: "Anular el control de actividad",
  recordingIndicatorText: "Rec",
  hintMoveFaceFrontOfCameraText: "Mover la cara delante de la cámara",
  hintTooManyFacesText: "Asegúrese de que sólo haya una cara delante de la cámara",
  hintFaceDetectedText: "Cara detectada",
  hintCanNotIdentifyText: "Mover la cara delante de la cámara",
  hintTooCloseText: "Retroceder",
  hintTooFarText: "Acércate",
  hintConnectingText: "Conectando...",
  hintVerifyingText: "Verificando...",
  hintIlluminationTooBrightText: "Pasar a la zona de atenuación",
  hintIlluminationTooDarkText: "Desplazarse a una zona más luminosa",
  hintIlluminationNormalText: "Condiciones de iluminación normales",
  hintHoldFaceForFreshnessText: "No te muevas",
  ...defaultErrorDisplayText,
};

export interface LivenessDisplayText
  extends HintDisplayText,
    CameraDisplayText,
    InstructionDisplayText,
    ErrorDisplayText,
    StreamDisplayText {}
