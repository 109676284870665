import { createCrudSlice } from "redux/sliceCreator";
import { FilterValues } from 'library/common/interfaces';
import { createPaginationReducers } from '../reducers/paginationReducers';
import { PaginationCaseReducers } from 'redux/reducers/paginationReducers';
import { Comite } from "library/models/Comite";
import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { PaginationStateOld } from 'library/common/interfaces';

export interface ComiteFilterValues extends FilterValues<Comite> {
  country?: number | null;
  province?: number | null;
  city?: number | null;
  district?: number | null;
  constituency?: number | null;
  zone?: number | null
}

export interface ComiteState extends PaginationStateOld<Comite, ComiteFilterValues> {}

const reducers = createPaginationReducers<Comite, ComiteState, ComiteFilterValues>();

export interface ComiteCaseReducers extends PaginationCaseReducers<Comite, ComiteState, ComiteFilterValues> {
  saveSuccess: CaseReducer<ComiteState, PayloadAction<Comite>>;
}

const initialState: ComiteState = {
  results: [],
  total: 0,
  filtered: 0,
  page: 1,
  pageSize: 10,
  filter: {
    sortType: 'desc',
    sortBy: 'id',
    search: '',
    country: null,
    province: null,
    constituency: null,
    city: null,
    district: null,
    zone: null
  },
  hasError: false,
  isLoading: false,
  errorMessage: "",
};

export const comiteSlice = createCrudSlice<
  Comite,
  ComiteState,
  "comites",
  ComiteCaseReducers
>({
  name: "comites",
  idSource: "id",
  initialState,
  extend: { 
    ...reducers,
    saveSuccess: (state, action: PayloadAction<Comite>) => {
      const itemIndex = state.results.findIndex((comite) => {
          return comite.id === action.payload.id;
        }),
        comiteExists = itemIndex !== -1;
      
      if (comiteExists) {
        state.results.splice(itemIndex, 1, action.payload)
      } else {
        state.results.unshift(action.payload);
      }

      state.isLoading = false;
      state.hasError = false;
      state.errorMessage = "";
    },
  },
});

export const actions = comiteSlice.actions;
