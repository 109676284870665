import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DemarcationFilterValues } from "library/components/DemarcationFilter/DemarcationFilter";
import { DateUnit, DemarcationUnit } from "library/common/enums";
import moment from "moment";

export interface GrowthFilterState {
  demarcationLabels: Record<keyof DemarcationFilterValues, string | null>;
  demarcations: DemarcationFilterValues;

  dateUnit: DateUnit;
  dateFrom: string;
  dateTo: string;
  filterApplied: boolean;
}

const initialState: GrowthFilterState = {
  filterApplied: true,
  dateUnit: DateUnit.Month,
  dateFrom: moment().subtract("month", 12).format(),
  dateTo: moment().format(),
  demarcationLabels: {
    countryId: null,
    provinceId: null,
    constituencyId: null,
    cityId: null,
    districtId: null,
    zoneId: null,
    parajeId: null,
    sectionId: null,
    recintoId: null,
    collegeId: null,
  },
  demarcations: {
    countryId: null,
    provinceId: null,
    constituencyId: null,
    cityId: null,
    districtId: null,
    zoneId: null,
    parajeId: null,
    sectionId: null,
    recintoId: null,
    collegeId: null,
  },
};

export const growthFilterSlice = createSlice({
  name: "growthFilter",
  initialState,
  reducers: {
    updateDemarcations(state, action: PayloadAction<DemarcationFilterValues>) {
      state.demarcations = action.payload;
    },
    updateDemarcationLabel(
      state,
      action: PayloadAction<GrowthFilterState["demarcationLabels"]>
    ) {
      state.demarcationLabels = action.payload;
    },
    updateDateUnit(state, action: PayloadAction<DateUnit>) {
      state.dateUnit = action.payload;
      state.dateFrom = moment(state.dateFrom).startOf(state.dateUnit).format();
      state.dateTo = moment(state.dateTo).endOf(state.dateUnit).format();
      state.filterApplied = false;
    },
    updateDateFrom(state, action: PayloadAction<string>) {
      state.dateFrom = action.payload;
      state.filterApplied = false;
    },
    updateDateTo(state, action: PayloadAction<string>) {
      state.dateTo = action.payload;
      state.filterApplied = false;
    },
    applyFilter(state) {
      state.filterApplied = true;
    }
  },
});

export const actions = growthFilterSlice.actions;
