import { createCrudSlice } from "redux/sliceCreator";
import { FilterValues } from "library/common/interfaces";
import { createPaginationReducers } from "../reducers/paginationReducers";
import { PaginationCaseReducers } from "redux/reducers/paginationReducers";
import { Designation } from "library/models/Designation";
import { PaginationStateOld } from '../../library/common/interfaces';

export interface DesignationFilterValues
  extends FilterValues<Designation>,
    Partial<Designation> {}

export interface DesignationState extends PaginationStateOld<Designation, DesignationFilterValues> {}

const reducers = createPaginationReducers<Designation, DesignationState>();

const initialState: DesignationState = {
  results: [],
  total: 0,
  filtered: 0,
  page: 1,
  pageSize: 10,
  filter: {
    sortType: "desc",
    sortBy: "nombres",
    search: "",
  },
  hasError: false,
  isLoading: false,
  errorMessage: "",
};

export const designationSlice = createCrudSlice<
  Designation,
  DesignationState,
  "designations",
  PaginationCaseReducers<Designation, DesignationState>
>({
  name: "designations",
  idSource: "cedula",
  initialState,
  extend: { ...reducers },
});

export const actions = designationSlice.actions;
