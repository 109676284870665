import { AbstractAPIService } from "./AbstractApiService";
import { getText } from "i18n";
import { backendMessages } from "library/common/constants";
import { cleanCardId } from "library/helpers/clean-card-id";

export type FaceCheckResult = { isLive: boolean; matchFaces: boolean };

type BecomeLoyalValidationValues = {
  loyalParty: string;
  candidacyLevelId?: number;
  candidateId?: number;
  phoneNumber?: string;
  referredBy?: string;
  sessionId: string;
  cardId: string;
};

export interface FaceLivenessRepository {
  createAWSFaceLivenessSession: () => Promise<{ sessionId: string }>;
  getFaceCheckResults: (
    sessionId: string,
    cardId: string
  ) => Promise<FaceCheckResult>;
  becomeLoyalValidation: (
    values: BecomeLoyalValidationValues
  ) => Promise<FaceCheckResult>;
  compareFaceWithCardIdPhoto: (
    cardId: string,
    file: File
  ) => Promise<{ similarity: number }>;
}

export class FaceLivenessService
  extends AbstractAPIService
  implements FaceLivenessRepository
{
  createAWSFaceLivenessSession(): Promise<{ sessionId: string }> {
    return new Promise((resolve, reject) => {
      this.client
        .get<{ sessionId: string }>("/aws/session/create")
        .then((res) => {
          resolve({ sessionId: res.data.sessionId });
        })
        .catch(() => reject(getText("alerts.create_session_failed")));
    });
  }

  getFaceCheckResults(
    sessionId: string,
    cardId: string
  ): Promise<FaceCheckResult> {
    return new Promise((resolve, reject) => {
      this.client
        .get<FaceCheckResult>("/aws/session/get", {
          params: { sessionId, cedula: cleanCardId(cardId) },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => reject(getText("alerts.get_session_failed")));
    });
  }

  becomeLoyalValidation({
    loyalParty,
    candidacyLevelId,
    candidateId,
    referredBy,
    phoneNumber,
    sessionId,
    cardId,
  }: BecomeLoyalValidationValues): Promise<FaceCheckResult> {
    return new Promise((resolve, reject) => {
      this.client
        .get<FaceCheckResult>("/aws/session/get", {
          params: {
            sessionId,
            partido: loyalParty,
            codigo_candidato: candidateId,
            codigo_nivel_candidatura: candidacyLevelId,
            cedula: cleanCardId(cardId),
            numero_telefonico: phoneNumber,
            referido_por: referredBy
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => reject(getText("alerts.get_session_failed")));
    });
  }

  compareFaceWithCardIdPhoto(
    cardId: string,
    file: File
  ): Promise<{ similarity: number }> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append("imageFile", file);

      this.client
        .post<{ similarity: number }>("/aws/compare/faces", formData, {
          params: { cedula: cleanCardId(cardId) },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          // TODO: Check this code works
          const errorCode: number = err.response.data.error;
          const errorMessage = errorCode
            ? backendMessages[errorCode]
            : getText("backend.unexpected_error");

          reject(errorMessage);
        });
    });
  }
}
