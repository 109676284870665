import { FilterValues, Mapper, Pagination } from "library/common/interfaces";
import { MemberFormSchema } from "features/MemberList/MemberForm/MemberFormSchema";
import * as dto from "../dto/member-dto";
import { Member, MemberDetail } from "library/models/Member";
import { paginationSize } from "library/common/constants";
import { cleanCardId } from "../../helpers/clean-card-id";

export class MemberMapper
  implements Mapper<Member, dto.GetMember, dto.CreateMember, MemberFormSchema>
{
  formSchemaToAPI(schema: MemberFormSchema): dto.CreateMember {
    return {
      cedula: cleanCardId(schema.cardId),
      apodo: schema.nickname,
      email: schema.email,
      comentario: schema.commentary,
      calle: schema.street,
      no_casa: schema.houseNumber,
      codigo_fuente_apoyo: Number(schema.fuenteApoyo),
      codigo_nivel_academico: Number(schema.nivelAcademico),
      codigo_ocupacion: Number(schema.ocupacion),
      codigo_organismo_asociado: Number(schema.organismoAsociado),
      direccion_referencia: schema.referenceAddress,
      id_ciudad: Number(schema.city),
      id_pais: Number(schema.country),
      id_paraje: Number(schema.paraje),
      id_provincia: Number(schema.province),
      id_seccion: Number(schema.section),
      direccion: `${schema.street} ${schema.houseNumber}`,
      // residencial: schema.residentialNumber
    };
  }

  fromAPI(data: dto.GetMember): Member {
    return {
      id: data.id,
      cardId: data.cedula,
      firstName: data.nombres,
      lastName: data.apellidos,
      fullName: `${data.nombres} ${data.apellidos}`,
      email: data.email,
      birthdate: data.fecha_nacimiento,
      nickname: data.apodo,
      commentary: data.comentario,
      address: data.direccion,
      street: data.calle,
      houseNumber: data.no_casa,
      country: Number(data.id_pais),
      province: Number(data.id_provincia),
      city: Number(data.id_ciudad),
      section: Number(data.id_seccion),
      paraje: Number(data.id_paraje),
      fuenteApoyo: data.codigo_fuente_apoyo,
      organismoAsociado: data.codigo_organismo_asociado,
      ocupacion: data.codigo_ocupacion,
      nivelAcademico: data.codigo_nivel_academico,
      referenceAddress: data.direccion_referencia,
      residentialNumber: "",
    };
  }

  fromAPIPaginated(data: dto.GetPaginatedMember): Pagination<Member> {
    const members = data.data.map(this.fromAPI);

    return {
      total: data.militantes_count,
      filtered: data.militantes_count_filtered,
      pageSize: paginationSize,
      page: data.page,
      results: members,
    };
  }

  fromAPIDetailed(data: dto.GetMemberDetail): MemberDetail {
    return {
      cardId: data.cedula,
      nickname: data.apodo,
      firstName: data.nombres,
      lastName: data.apellidos,
      genre: data.codigo_sexo === 1 ? "M" : "F",
      countryName: data.pais,
      provinceName: data.provincia,
      constituencyName: data.circunscripcion,
      cityName: data.ciudad,
      districtName: data.distrito_municipal,
      sectionName: data.seccion,
      parajeName: data.paraje,
      precintName: data.recinto_electoral,
      collegeName: data.colegio_electoral,
      constituencyId: data.codigo_circunscripcion,
      countryId: data.codigo_pais,
      provinceId: data.codigo_provincia,
      cityId: data.codigo_ciudad,
      districtId: data.codigo_distrito_municipal,
      sectionId: data.codigo_seccion,
      parajeId: data.codigo_paraje,
      precintId: data.codigo_recinto_electoral,
      collegeId: data.codigo_colegio_electoral,
      isMilitant: data.militante,
      isSympathizer: data.simpatizante,
      photoUrl: `${
        process.env.REACT_APP_API_URL
      }/militantes/foto?c=${cleanCardId(data.cedula)}&t=img`,
    };
  }

  fromFilterToQueryParams(filter: Partial<Member>): dto.GetMemberParams {
    return {
      cedula: filter.cardId,
      // nombres: filter.firstName,
      // apellidos: filter.lastName
    };
  }
}
