import { Precandidate } from "data/models/Precandidate";
import { createSlice } from "@reduxjs/toolkit";
import { createCrudReducers } from "data/store/utils/createCrudReducers";
import { CrudState, PaginationState } from "library/common/interfaces";
import { createPaginationReducers } from "../utils/createPaginationReducers";
import pagination from "library/constants/pagination";

export interface PrecandidateState
  extends CrudState<Precandidate>,
    PaginationState<Precandidate> {}

const initialState: PrecandidateState = {
  items: [],
  page: 1,
  total: 0,
  totalFiltered: 0,
  pageSize: pagination.DEFAULT_PAGE_SIZE,
  filter: {},
  isLoading: false,
  errorMessage: "",
  hasError: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
};

export const precandidateSlice = createSlice({
  name: "precandidates",
  initialState,
  reducers: {
    ...createPaginationReducers<Precandidate, PrecandidateState>(),
    ...createCrudReducers<Precandidate, PrecandidateState>({ idSource: "id" }),
  },
});

export const precandidatesActions = precandidateSlice.actions;
