import { createCrudSlice } from "redux/sliceCreator";
import { CrudStateOld } from "library/common/interfaces";
import { FilterValues } from "library/common/interfaces";
import { createPaginationReducers } from "../reducers/paginationReducers";
import { PaginationCaseReducers } from "redux/reducers/paginationReducers";
import { CollectedData } from "library/models/CollectedData";
import { PaginationStateOld } from '../../library/common/interfaces';

export interface DataCollectionState extends PaginationStateOld<CollectedData> {}

const reducers = createPaginationReducers<CollectedData, DataCollectionState>();

const initialState: DataCollectionState = {
  results: [],
  total: 0,
  filtered: 0,
  page: 1,
  pageSize: 10,
  filter: {
    sortType: "desc",
    sortBy: "id",
    search: "",
  },
  hasError: false,
  isLoading: false,
  errorMessage: "",
};

export const dataCollectionSlice = createCrudSlice<
  CollectedData,
  DataCollectionState,
  "dataCollection",
  PaginationCaseReducers<CollectedData, DataCollectionState>
>({
  name: "dataCollection",
  idSource: "id",
  initialState,
  extend: { ...reducers },
});

export const actions = dataCollectionSlice.actions;
