import React, { useMemo, useRef, useState, useEffect } from "react";
import { AutoComplete, Select } from "antd";
import { useTranslation } from "react-i18next";
import type { SelectProps } from "antd/es/select";
import debounce from "lodash/debounce";
import AppLoader from "../AppLoader/AppLoader";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  watchValue?: any | any[];
  noResults?: React.ReactNode;
  children?: React.ReactNode;
  labelOption?: ValueType;
  asAutocomplete?: boolean;
}

export default function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 700,
  watchValue,
  noResults,
  labelOption,
  asAutocomplete,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);
  const { t } = useTranslation();

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string, clearOptions: boolean = true) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (clearOptions) setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        if (labelOption) {
          newOptions.unshift(labelOption)
        }
        
        setOptions(newOptions);
        setFetching(false);
      });
    };
    
    return loadOptions;
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    debounceFetcher("");
  }, [watchValue]);

  const Component = asAutocomplete ? AutoComplete : Select;

  return (
    <Component
      showSearch
      size="large"
      filterOption={false}
      onSearch={debounce(debounceFetcher, debounceTimeout)}
      {...props}
      onSelect={() => debounceFetcher("", false)}
      options={options}
      notFoundContent={
        fetching ? (
          <AppLoader size="default" text={t("loading.searching")} />
        ) : (
          noResults || t("labels.no_results")
        )
      }
    />
  );
}
