import { configureStore } from "@reduxjs/toolkit";

import { authSlice } from "./slices/authSlice";
import { userSlice } from "./slices/userSlice";
import { territorySlice } from "./slices/territorySlice";
import { memberSlice } from "./slices/memberSlice";
import { positionSlice } from "./slices/positionSlice";
import { roleSlice } from "./slices/roleSlice";
import { moduleSlice } from "./slices/moduleSlice";
import { permissionSlice } from "./slices/permissionSlice";
import { comiteSlice } from "./slices/comiteSlice";
import { comiteTemplateSlice } from "./slices/comiteTemplateSlice";
import { dataCollectionSlice } from "./slices/dataCollectionSlice";
import { designationSlice } from "./slices/designationSlice";
import { leaderSlice } from "./slices/leaderSlice";
import { growthFilterSlice } from "./slices/growthFilterSlice";
import { precandidateSlice } from "data/store/slices/precandidateSlice";

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [territorySlice.name]: territorySlice.reducer,
    [memberSlice.name]: memberSlice.reducer,
    [positionSlice.name]: positionSlice.reducer,
    [roleSlice.name]: roleSlice.reducer,
    [moduleSlice.name]: moduleSlice.reducer,
    [permissionSlice.name]: permissionSlice.reducer,
    [comiteSlice.name]: comiteSlice.reducer,
    [comiteTemplateSlice.name]: comiteTemplateSlice.reducer,
    [dataCollectionSlice.name]: dataCollectionSlice.reducer,
    [designationSlice.name]: designationSlice.reducer,
    [leaderSlice.name]: leaderSlice.reducer,
    [growthFilterSlice.name]: growthFilterSlice.reducer,
    [precandidateSlice.name]: precandidateSlice.reducer,
  },
});

export default store;
