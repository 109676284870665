import { CrudStateOld } from "library/common/interfaces";
import { createCrudSlice } from "redux/sliceCreator";
import { Territory } from 'library/models/Territory';

export interface TerritoryState extends CrudStateOld<Territory> {}

const initialState: TerritoryState = {
  results: [],
  isLoading: false,
  hasError: false,
  errorMessage: "",
};

export const territorySlice = createCrudSlice<Territory, TerritoryState, "territories">({
  name: "territories",
  idSource: "id",
  initialState,
});

export const { ...actions } = territorySlice.actions;
