import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Result, Row } from "antd";
import { paths } from "library/common/constants";

interface PageErrorProps {
  title: string;
  subTitle?: string;
  actionLabel?: string;
  onActionClick?: () => void;
}

export default function PageError(props: PageErrorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    title,
    subTitle,
    actionLabel = t("links.back_home"),
    onActionClick = () => navigate(paths.admin.index),
  } = props;

  return (
    <Row align="middle" style={{ height: "100%" }}>
      <Col span={24}>
        <Result
          title={title}
          subTitle={subTitle}
          extra={
            <Button size="large" type="primary" onClick={onActionClick}>
              {actionLabel}
            </Button>
          }
        />
      </Col>
    </Row>
  );
}
