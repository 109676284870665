import { AuthUser } from "library/models/User";
import { GetAuthUser } from "../dto/auth-dto";
import { PermissionMapper } from "./PermissionMapper";

export class AuthMapper {
  userFromAPI(data: GetAuthUser): AuthUser {
    const permissionMapper = new PermissionMapper();
    const permissions = Object.values(data.modulos.modulos_data)
      .flat()
      .map(permissionMapper.fromAPI);

    return {
      id: data.uid,
      lastName: data.apellidos,
      firstName: data.nombres,
      cardId: data.cid,
      fullName: `${data.nombres} ${data.apellidos}`,
      roleId: data.rl,
      changePassRequired: data.cambiar_clave === "Y",
      moduleKeys: data.modulos.modulos_keys,
      permissions,
      territories: {
        countryId: data.territorios.codigo_pais,
        provinceId: data.territorios.codigo_provincia,
        cityId: data.territorios.codigo_ciudad,
        constituencyId: data.territorios.codigo_circunscripcion,
        extConstituencyId: data.territorios.codigo_circunscripcion_ext,
        districtId: data.territorios.codigo_distrito_municipal,
        regionId: data.territorios.codigo_region,
        zoneId: data.territorios.codigo_zona,
        sectionId: data.territorios.codigo_seccion,
        parajeId: data.territorios.codigo_paraje,
        recintoId: data.territorios.codigo_recinto,
        collegeId: data.territorios.codigo_colegio,
      },
    };
  }
}
