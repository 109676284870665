import React from "react";
import { Layout, Row, Col, Grid } from "antd";
import { Outlet } from "react-router-dom";

import classes from "./AuthPanel.module.css";

export default function AuthPanel() {
  const { md: isLarge } = Grid.useBreakpoint();

  return (
    <Layout className={classes.layout}>
      <Layout.Content className={classes.content}>
        <Row className={classes.row}>
          {isLarge ? (
            <Col className={classes.hero} md={10} lg={9} xl={10} xxl={12}></Col>
          ) : null}
          <Col
            span={24}
            sm={18}
            md={14}
            lg={15}
            xl={14}
            xxl={12}
            className={classes.form}
          >
            <Outlet />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}
