import { CaseReducer, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";
import { CrudState } from "library/common/interfaces";
import { ErrorMessage, ID } from "library/common/types";

export type CrudAction = "list" | "create" | "edit" | "delete";

export interface CrudReducer<T, State extends CrudState<T>>
  extends Slice<State, CrudCaseReducers<T, State>> {}

export interface CrudCaseReducers<T, State extends CrudState<T>>
  extends SliceCaseReducers<State> {
  startLoading: CaseReducer<State, PayloadAction<CrudAction>>;
  getSuccess: CaseReducer<State, PayloadAction<T[]>>;
  getFailed: CaseReducer<State, PayloadAction<ErrorMessage>>;
  createSuccess: CaseReducer<State, PayloadAction<T>>;
  createFailed: CaseReducer<State, PayloadAction<ErrorMessage>>;
  editSuccess: CaseReducer<State, PayloadAction<T>>;
  editFailed: CaseReducer<State, PayloadAction<ErrorMessage>>;
  deleteSuccess: CaseReducer<State, PayloadAction<ID>>;
  deleteFailed: CaseReducer<State, PayloadAction<ErrorMessage>>;
}

export function createCrudReducers<T, State extends CrudState<T>>({
  idSource,
}: {
  idSource: keyof T;
}) {
  return {
    startLoading: (state: State, action: PayloadAction<CrudAction>) => {
      switch(action.payload) {
        case "list":
          state.isLoading = true;
          break;
        case "create":
          state.isCreating = true;
          break;
        case "edit":
          state.isEditing = true;
          break;
        case "delete":
          state.isDeleting = true;
          break;
      }
    },
    getSuccess: (state: State, action: PayloadAction<State["items"]>) => {
      state.items = action.payload;
      state.isLoading = false;
      state.hasError = false;
      state.errorMessage = "";
    },
    getFailed: (state: State, action: PayloadAction<ErrorMessage>) => {
      state.isLoading = false;
      state.hasError = true;
      state.errorMessage = action.payload;
    },
    createSuccess: (state: State, action: PayloadAction<T>) => {
      state.items.unshift(action.payload);
      state.isCreating = false;
      state.hasError = false;
      state.errorMessage = "";
    },
    createFailed: (state: State, action: PayloadAction<ErrorMessage>) => {
      state.isCreating = false;
      state.hasError = true;
      state.errorMessage = action.payload;
    },
    editSuccess: (state: State, action: PayloadAction<T>) => {
      const itemIndex = state.items.findIndex((result: any) => {
          return result[idSource] === action.payload[idSource];
        }),
        itemExists = itemIndex !== -1;

      if (itemExists) {
        state.items.splice(itemIndex, 1, action.payload);
      }

      state.isEditing = false;
      state.hasError = false;
      state.errorMessage = "";
    },
    editFailed: (state: State, action: PayloadAction<ErrorMessage>) => {
      state.isEditing = false;
      state.hasError = true;
      state.errorMessage = action.payload;
    },
    deleteSuccess: (state: State, action: PayloadAction<ID>) => {
      const itemIndex = state.items.findIndex(
          (result: any) => result[idSource] === action.payload
        ),
        itemExists = itemIndex !== -1;

      if (itemExists) state.items.splice(itemIndex, 1);

      state.isDeleting = false;
      state.hasError = false;
      state.errorMessage = "";
    },
    deleteFailed: (state: State, action: PayloadAction<ErrorMessage>) => {
      state.isDeleting = false;
      state.hasError = true;
      state.errorMessage = action.payload;
    },
  };
}
