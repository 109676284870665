import { UtilsMapper } from "library/api/mappers/UtilsMapper";
import { AbstractAPIService } from "./AbstractApiService";
import { APIResponse } from '../../common/interfaces';
import * as dto from "../dto/utils-dto";
import * as models from "library/models/Utils";
import { cleanCardId } from '../../helpers/clean-card-id';

interface UtilsRepository {
  getFuentesApoyo: (filter: dto.GetFuenteApoyoFilter) => Promise<models.FuenteApoyo[]>;
  getOrganismoAsociados: (filter: dto.GetOrganismoAsociadoFilter) => Promise<models.OrganismoAsociado[]>;
  getOcupaciones: (filter: dto.GetOcupacionFilter) => Promise<models.Ocupacion[]>;
  getNivelesAcademicos: (filter: dto.GetNivelAcademicoFilter) => Promise<models.NivelAcademico[]>;
  getEstadosCiviles: (filter: dto.GetUtilFilter) => Promise<models.EstadoCivil[]>;
  getGenders: (filter: dto.GetUtilFilter) => Promise<models.Gender[]>;
  getFamiliarCompositions: (filter: dto.GetUtilFilter) => Promise<models.FamiliarComposition[]>;
  getIncomeLevels: (filter: dto.GetUtilFilter) => Promise<models.IncomeLevel[]>;
  getReligions: (filter: dto.GetUtilFilter) => Promise<models.Religion[]>;
  getBloodTypes: (filter: dto.GetUtilFilter) => Promise<models.BloodType[]>;
  getCargosCampana: (filter: dto.GetUtilFilter) => Promise<models.CargoCampana[]>;
  getPartidaryOrganism: (filter: dto.GetUtilFilter) => Promise<models.PartidaryOrganism[]>;
  getOrganismTypes: (filter: dto.GetUtilFilter) => Promise<models.OrganismType[]>;
  getBaseballTeams: (filter: dto.GetUtilFilter) => Promise<models.BaseballTeam[]>;
  getBasketballTeams: (filter: dto.GetUtilFilter) => Promise<models.BasketballTeam[]>;
  getSportClubs: (filter: dto.GetUtilFilter) => Promise<models.SportClub[]>;

  verifyCitizen: (cardId: string) => Promise<models.Citizen>;
}

export class UtilsService extends AbstractAPIService implements UtilsRepository {
  mapper: UtilsMapper;

  constructor() {
    super();
    this.mapper = new UtilsMapper();
  }

  getUtils<T, QueryParams, DataReceived>(
    path: string,
    params: QueryParams,
    mapFunc: (data: DataReceived) => T
  ): Promise<T[]> {
    return new Promise((resolve, reject) => {
      this.client
        .get<APIResponse<DataReceived[]>>(path, { params })
        .then((res) => {
          const results = res.data.data.map(mapFunc);
          resolve(results);
        })
        .catch((err) => {
          reject("No se pudo cargar los resultados")
        });
    });
  }

  getFuentesApoyo(filter: dto.GetFuenteApoyoFilter): Promise<models.FuenteApoyo[]> {
    return this.getUtils("utils/fuente_apoyo/get", filter, this.mapper.fuenteApoyoFromAPI);
  }

  getOrganismoAsociados(filter: dto.GetOrganismoAsociadoFilter): Promise<models.OrganismoAsociado[]> {
    return this.getUtils("utils/organismo_asociado/get", filter, this.mapper.organismoAsociadoFromAPI);
  }
  
  getOcupaciones(filter: dto.GetOcupacionFilter): Promise<models.Ocupacion[]> {
    return this.getUtils("utils/ocupacion/get", filter, this.mapper.ocupacionFromAPI);
  }

  getNivelesAcademicos(filter: dto.GetNivelAcademicoFilter): Promise<models.NivelAcademico[]> {
    return this.getUtils("utils/nivel_academico/get", filter, this.mapper.nivelAcademicoFromAPI);
  }

  getEstadosCiviles(filter: dto.GetUtilFilter): Promise<models.EstadoCivil[]> {
    return this.getUtils("utils/estado_civil/get", filter, this.mapper.estadoCivilFromAPI);
  }

  getGenders(filter: dto.GetUtilFilter): Promise<models.Gender[]> {
    return this.getUtils("utils/sexo/get", filter, this.mapper.genderFromAPI);
  }

  getFamiliarCompositions(filter: dto.GetUtilFilter): Promise<models.FamiliarComposition[]> {
    return this.getUtils("utils/composicion_familiar/get", filter, this.mapper.familiarCompositionFromAPI);
  }

  getIncomeLevels(filter: dto.GetUtilFilter): Promise<models.IncomeLevel[]> {
    return this.getUtils("utils/nivel_ingreso/get", filter, this.mapper.incomeLevelFromAPI);
  }

  getReligions(filter: dto.GetUtilFilter): Promise<models.Religion[]> {
    return this.getUtils("utils/religion/get", filter, this.mapper.religionFromAPI);
  }

  getBloodTypes(filter: dto.GetUtilFilter): Promise<models.BloodType[]> {
    return this.getUtils("utils/tipo_sangre/get", filter, this.mapper.bloodTypeFromAPI);
  }

  getCargosCampana(filter: dto.GetUtilFilter): Promise<models.CargoCampana[]> {
    return this.getUtils("utils/cargo_campana/get", filter, this.mapper.cargoCampanaFromAPI);
  }

  getPartidaryOrganism(filter: dto.GetPartidaryOrganismFilter): Promise<models.PartidaryOrganism[]> {
    return this.getUtils("utils/organismo_partidario/get", filter, this.mapper.partidaryOrganismFromAPI);
  }

  getOrganismTypes(filter: dto.GetUtilFilter): Promise<models.OrganismType[]> {
    return this.getUtils("utils/tipo_organismo/get", filter, this.mapper.organismTypeFromAPI);
  }
  
  getBaseballTeams(filter: dto.GetUtilFilter): Promise<models.BaseballTeam[]> {
    return this.getUtils("utils/equipo_baseball/get", filter, this.mapper.baseballTeamFromAPI);
  }

  getBasketballTeams(filter: dto.GetUtilFilter): Promise<models.BasketballTeam[]> {
    return this.getUtils("utils/equipo_basketball/get", filter, this.mapper.basketballTeamFromAPI);
  }

  getSportClubs(filter: dto.GetUtilFilter): Promise<models.SportClub[]> {
    return this.getUtils("utils/club_deportivos/get", filter, this.mapper.sportClubFromAPI);
  }
  
  getCandidacyLevels(filter: dto.GetCandidacyLevelFilter): Promise<models.CandicacyLevel[]> {
    return this.getUtils("/electoral/fidelizacion/nivel/get", filter, this.mapper.candidacyLevelFromAPI);
  }
  
  getCandidates(filter: dto.GetCandidatesFilter): Promise<models.Candicate[]> {
    return this.getUtils("/electoral/fidelizacion/candidatos/get", filter, this.mapper.candidateFromAPI);
  }

  verifyCitizen(cardId: string): Promise<models.Citizen> {
    return new Promise((resolve, reject) => {
      const url = "/militantes/verificate/consulta?cedula=" + cleanCardId(cardId);
      this.client.post<APIResponse<dto.GetCitizen>>(url)
        .then(res => {
            const citizen = this.mapper.citizenFromAPI(res.data.data);
            resolve(citizen);
        })
        .catch(() => reject("errors.citizen_not_found"))
    })
  }

  async getDesignationsArea(): Promise<{area: string}[] | undefined> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/nomina/areas/get")
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("No se pudo cargar las áreas");
        });
    });
  }

  async getDesignationsFunctions(): Promise<{funcion: string}[] | undefined> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/nomina/funciones/get")
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("No se pudo cargar las funciones");
        });
    });
  }

  async getDesignationsInstitutions(): Promise<{institucion: string}[] | undefined> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/nomina/instituciones/get")
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("No se pudo cargar las instituciones");
        });
    });
  }

  async getPlanchaCargos(): Promise<{cargo: string}[]> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/nomina/dirigentes/cargos/get")
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("No se pudo cargar los cargos");
        });
    });
  }

}
