import { CrudStateOld } from "library/common/interfaces";
import { createCrudSlice } from "redux/sliceCreator";
import { ComiteTemplate } from "library/models/ComiteTemplate";

export interface ComiteTemplateState extends CrudStateOld<ComiteTemplate> {}

const initialState: ComiteTemplateState = {
  results: [],
  isLoading: false,
  hasError: false,
  errorMessage: "",
};

export const comiteTemplateSlice = createCrudSlice<
  ComiteTemplate, 
  ComiteTemplateState, 
  "comiteTemplates"
>({
  name: "comiteTemplates",
  idSource: "id",
  initialState,
});

export const { ...actions } = comiteTemplateSlice.actions;
