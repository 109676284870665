import axios, { AxiosInstance } from 'axios';
import { keys } from 'library/common/constants';

export const backend = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const localToken = localStorage.getItem(keys.tokenLocalStorage) || "";

backend.interceptors.request.use(
  (config: any) => {
    const token = localToken;
    if (token) {
      config.headers['Authorization'] = token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
)

export abstract class APIService {
  protected client!: AxiosInstance;
  
  constructor() {
    this.client = backend;
  }
}