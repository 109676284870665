import { getText } from "i18n";
import { ContentType, ComiteStatus } from "./enums";
import { EditOutlined } from "@ant-design/icons";

export const paths = {
  auth: {
    index: "/auth",
    login: {
      index: "iniciar-sesion",
      fullPath: "/auth/iniciar-sesion",
    },
    signup: {
      index: "registrarse",
      fullPath: "/auth/registrarse",
    },
    confirmEmail: {
      index: "confirmar-correo",
      fullPath: "/auth/confirmar-correo",
    },
    forgotPassword: {
      index: "recuperar",
      fullPath: "/auth/recuperar",
    },
    passwordReset: {
      index: "restablecer",
      fullPath: "/auth/restablecer",
    },
  },
  admin: {
    index: "/",
    settings: {
      index: "/cuenta",
      general: {
        index: "general",
        fullPath: "/cuenta/general",
      },
      password: {
        index: "password",
        fullPath: "/cuenta/password",
      },
    },
  },
  modules: {
    index: "/mantenimiento",
    mails: {
      index: "correos",
      fullPath: "/correos",
    },
    users: {
      index: "usuarios",
      fullPath: "/usuarios",
    },
    members: {
      index: "electores",
      fullPath: "/electores",
    },
    positions: {
      index: "cargos",
      fullPath: "/cargos",
    },
    roles: {
      index: "roles",
      fullPath: "/roles",
    },
    modules: {
      index: "modulos",
      fullPath: "/modulos",
    },
    permissions: {
      index: "permisos",
      fullPath: "/permisos",
    },
    territories: {
      index: "territorios",
      fullPath: "/territorios",
    },
    enclosures: {
      index: "recintos",
      fullPath: "/recintos",
    },
    comiteTemplates: {
      index: "comite-templates",
      fullPath: "/comite-templates",
    },
    comites: {
      index: "comites",
      fullPath: "/comites",
      detail: {
        index: ":id",
        fullPath: '/comites/:id',
        reverse: ({ id }: { id: string }) => `/comites/${id}`,
      },
    },
    comitesRevision: {
      index: "comites-revision",
      fullPath: "/comites-revision",
      detail: {
        index: ":id",
        fullPath: '/comites-revision/:id',
        reverse: ({ id }: { id: string }) => `/comites-revision/${id}`,
      },
    },
    statistics: {
      index: "estadisticas",
      fullPath: "/estadisticas"
    },
    verify: {
      index: "consulta-ciudadano",
      fullPath: "/consulta-ciudadano"
    },
    designations: {
      index: "designaciones",
      fullPath: "/designaciones"
    },
    leaders: {
      index: "dirigentes",
      fullPath: "/dirigentes"
    },
    dataCollection: {
      index: "collection-datos",
      fullPath: "/collection-datos"
    },
    growth: {
      index: "crecimiento",
      fullPath: "/crecimiento"
    },
    questionary: {
      index: "cuestionario",
      fullPath: "/cuestionario"
    },
    proscyr: {
      index: "proscyr",
      fullPath: "proscyr"
    },
    pind: {
      index: "pind",
      fullPath: "pind"
    }
  },
};

export const keys = {
  linkTokenParam: "v",
  linkTokenIdParam: "t",
  tokenLocalStorage: "tkbase64",
  domainParamName: "d",
  viewParamName: "view",
  actionParamName: "action",
  idParamName: "id",
  emailDomainSuffix: "@ssp.do",
};

export const contentTypeLabels = {
  [ContentType.TEXT]: getText("contentTypes.plain_text"),
  [ContentType.JPG]: getText("contentTypes.jpg"),
  [ContentType.PNG]: getText("contentTypes.png"),
  [ContentType.JPEG]: getText("contentTypes.jpeg"),
  [ContentType.CSV]: getText("contentTypes.csv"),
  [ContentType.PDF]: getText("contentTypes.pdf"),
  [ContentType.DOC]: getText("contentTypes.doc"),
  [ContentType.DOCX]: getText("contentTypes.docx"),
  [ContentType.XLS]: getText("contentTypes.xls"),
  [ContentType.XLSX]: getText("contentTypes.xlsx"),
};

export const comiteStatusColors = {
  [ComiteStatus.Initial]: "red",
  [ComiteStatus.Draft]: "blue",
  [ComiteStatus.Review]: "red",
  [ComiteStatus.Sent]: "yellow",
  [ComiteStatus.Validated]: "green"
}

export const comiteStatusLabels = {
  [ComiteStatus.Initial]: "Pendiente",
  [ComiteStatus.Draft]: "Borrador",
  [ComiteStatus.Review]: "Corregir",
  [ComiteStatus.Sent]: "En Revisión",
  [ComiteStatus.Validated]: "Aprobado"
}

export const backendMessages: Record<string, string> = {
  "23505": getText("backend.item_already_exists"),
  "23502": getText("backend.item_must_not_be_null"),
  "23503": getText("backend.item_has_related_references"),
};

export const cardIdMask = "000-0000000-0";

export const paginationSize = 10;

export const positionPlaces = [
  "1er",
  "2do",
  "3ro",
  "4to",
  "5to",
  "6to",
  "7mo",
  "8vo",
  "9no",
  "10mo",
  "11avo",
  "12avo",
  "13avo",
  "14avo",
  "15avo",
  "16avo",
  "17avo",
  "18avo",
  "19avo",
  "20avo",
  "21avo",
  "22avo",
  "23avo",
  "24avo",
  "25avo",
  "26avo",
  "27avo",
  "28avo",
  "29avo",
  "30avo",
  "31avo",
  "32avo",
  "33avo",
  "34avo",
  "35avo",
  "36avo",
];

export const partyColors = {
  PRM: "#005BAA",
  PRM_2016: "#005BAA",
  PRM_2020: "#005BAA",
  PLD: "#42166F",
  PLD_2016: "#42166F",
  PLD_2020: "#42166F",
  FP: "#019E57",
  FP_2020: "#019E57",
  FP_2016: "#019E57",
  PRD: "#0000ff",
  PRSC: "#ff1c1c",

  // Colors
  ALPAIS: "#08afa4",
  PHD: "#fef03d",
  MODA: "#e75d27",
  BIS: "#0a4063",
  PCR: "#edd14f",
  DXC: "#2face1",
  UDC: "#ffeb57",
  PLR: "#126721",
  APD: "#087bd0",
  PPC: "#f19132",
  PAL: "#086438",
  PRSD: "#0b0b0b",
  PDP: "#4b8345",
  PDI: "#ff8312",
  PRI: "#ff2222",
  PUN: "#9c5e23",
  PNVC: "#0865b2",
  PAIS_POSIBLE: "#b7cc51",
  PPP: "#b7cc51",
  PP: "#b7cc51",
  PARTIDO_VERDE: "#55b94d",
  VERDE: "#55b94d",
  PVD: "#55b94d",
  PQDC: "#fedc24",
  F_AMPLIO: "#15903d",
  FRENTE_AMPLIO: "#15903d",
  FA: "#15903d",
  FUPU: "#08a952",
  FNP: "#080882",
  GENS: "#f59cc2",
  OD: "#553629",
  MA: "#de9b40",
  SA: "#bfbfbf",
  SF: "#bfbfbf",
  MHI: "#bfbfbf",
  MINPC: "#bfbfbf",
  MCNPC: "#bfbfbf",
  MIUP: "#bfbfbf",
};

export const partyNames = {
  PRM: "Partido Revolucionario Moderno",
  PRM_2016: "Partido Revolucionario Moderno",
  PRM_2020: "Partido Revolucionario Moderno",
  PLD: "Partido de la Liberación Dominicana",
  PLD_2016: "Partido de la Liberación Dominicana",
  PLD_2020: "Partido de la Liberación Dominicana",
  FP: "Fuerza del Pueblo",
  FP_2020: "Fuerza del Pueblo",
  FUPU: "Fuerza del Pueblo",
  FP_2016: "Fuerza del Pueblo",
  PRD: "Partido Revolucionario Dominicano",
  PRSC: "Partido Reformista Social Cristiano",

  // Colors
  ALPAIS: "Alianza País",
  'AL-PAIS': "Alianza País",
  PHD: "Partido Humanista Dominicano",
  MODA: "Partido Democrático Alternativo",
  BIS: "Bloque Institucional Social Demócrata",
  PCR: "Partido Cívico Renovador",
  DXC: "Dominicanos por el Cambio",
  UDC: "Unión Democrata Cristiana",
  PLR: "Partido Liberal Reformista",
  APD: "Alianza por la Democracia",
  PPC: "Partido Popular Cristiano",
  PAL: "Partido De Accion Liberal",
  PRSD: "Partido Revolucionario Social Demócrata",
  PDP: "Partido Demócrata Popular",
  PDI: "Partido Democrata Institucional",
  PRI: "Partido Revolucionario Institucional",
  PUN: "Partido de Unidad Nacional",
  PNVC: "Partido Nacional Voluntad Ciudadana",
  PAIS_POSIBLE: "Partido País Posible",
  'PAIS-POSIBLE': "Partido País Posible",
  PPP: "Partido País Posible",
  PP: "Partido País Posible",
  PARTIDO_VERDE: "Partido Verde Dominicano",
  'PARTIDO-VERDE': "Partido Verde Dominicano",
  VERDE: "Partido Verde Dominicano",
  PVD: "Partido Verde Dominicano",
  PQDC: "Partido Quisqueyano Demócrata Cristiano",
  F_AMPLIO: "Frente Amplio",
  FRENTE_AMPLIO: "Frente Amplio",
  FA: "Frente Amplio",
  'FRENTE-AMPLIO': "Frente Amplio",
  FNP: "Fuerza Nacional Progresista",
  GENS: "Generación de Servidores",
  OD: "Opción Democrática",
  MA: "Multiples Afiliciaciones",
  MULTIPLES: "Multiples Afiliciaciones",
  SA: "Sin Afiliación",
  'SIN-AFILIACIÓN': "Sin Afiliación",
  SF: "Sin Fidelizar",
  MHI: "Movimiento Humanista Independiente",
  MINPC: "MINPC",
  MCNPC: "Movimiento Comunitario Político Nosotros Pa’ Cuando",
  MIUP: "Movimiento Independiente Unidad y Progreso",
};

// TODO: Use translation only inside React lifecycle