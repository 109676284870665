import React from "react";
import { Alert, Button } from "antd";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsexports from "../../../../aws-exports";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import {
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
  Theme,
} from "@aws-amplify/ui-react";
import {
  Loader,
  ThemeProvider,
  Alert as AmplifyAlert,
} from "@aws-amplify/ui-react";
import { Modal } from "antd";
import { FaceLivenessService } from "library/api/services/FaceLivenessService";
import { AppLoader } from "library/components/AppLoader";
import { defaultLivenessDisplayText } from "./livenessDisplayTexts";
import { Trans } from "react-i18next";

Amplify.configure(awsexports);

interface BiometricsV3Props {
  cardId: string;
  loyalParty: string;
  candidacyLevelId?: number;
  candidateId?: number;
  phoneNumber?: string;
  referredBy?: string;
  onSuccess: () => void;
  onFinish: () => void;
}

const dictionary = {
  // use default strings for english
  en: null,
  es: {
    photosensitivyWarningHeadingText: "Advertencia de fotosensibilidad",
    photosensitivyWarningBodyText:
      "Esta verificación muestra luces de colores. Tenga cuidado si es fotosensible.",
    goodFitCaptionText: "Buen ajuste",
    tooFarCaptionText: "Demasiado lejos",
    hintCenterFaceText: "Centra tu cara",
    startScreenBeginCheckText: "Comenzar a verificar",
  },
};

export default function BiometrictsV3({
  cardId,
  loyalParty,
  candidacyLevelId,
  candidateId,
  phoneNumber,
  referredBy,
  onSuccess,
  onFinish,
}: BiometricsV3Props) {
  const [loading, setLoading] = React.useState(true);
  const [language, setLanguage] = React.useState<string>("en");
  const [createLivenessApiData, setCreateLivenessApiData] = React.useState<{
    sessionId: string;
  } | null>(null);

  const [results, setResults] = React.useState<{
    isLive: boolean;
    matchFaces: boolean;
  } | null>(null);

  const { tokens } = useTheme();

  const facelivenessService = new FaceLivenessService();

  React.useEffect(() => {
    const fetchCreateLiveness = async () => {
      setLoading(true);
      try {
        // Replace with the URL to your backend endpoint
        const data = await facelivenessService.createAWSFaceLivenessSession();
        // const response = await axios.get(
        //   "https://9022-152-167-229-145.ngrok.io/session/create"
        // );
        // const data = response.data;
        setCreateLivenessApiData(data);
      } catch (error) {
        console.error("Error fetching create liveness session:", error);
      }
      setLoading(false);
    };

    if (!createLivenessApiData) {
      fetchCreateLiveness();
    }
  }, [createLivenessApiData]);

  const handleAnalysisComplete = async () => {
    try {
      // Replace with the URL to your backend endpoint
      const data = await facelivenessService.becomeLoyalValidation({
        loyalParty,
        candidacyLevelId,
        candidateId,
        sessionId: createLivenessApiData?.sessionId || "",
        phoneNumber,
        referredBy,
        cardId
      });
 
      // const response = await axios.get(
      //   `https://9022-152-167-229-145.ngrok.io/session/get?sessionId=${createLivenessApiData?.sessionId}`
      // );
      // const data = response.data;
      setResults(data);

      if (data.isLive && data.matchFaces) {
        onSuccess();
      }
    } catch (error) {
      console.error("Error fetching liveness session result:", error);
    }
  };

  const successResult = (
    <div>
      <Alert
        style={{ marginBottom: 10 }}
        showIcon
        type="success"
        message="Verificación completa"
      />
      <Button type="primary" onClick={onFinish}>
        <Trans i18nKey="buttons.become_other_loyal" />
      </Button>
    </div>
  );

  const notMatchedResult = (
    <div>
      <Alert
        style={{ marginBottom: 10 }}
        showIcon
        type="error"
        message="La información facilitada no se corresponde al usuario en vivo"
      />

      <Button
        type="primary"
        onClick={() => {
          setResults(null);
          setCreateLivenessApiData(null);
        }}
      >
        Reintentar
      </Button>
    </div>
  );

  const notLiveResult = (
    <div>
      <Alert
        showIcon
        style={{ marginBottom: 10 }}
        type="error"
        message="Inténtelo nuevamente"
        description={
          <ul>
            <li>No trate de acercar fotos ni videos pregrabados.</li>
            <li>
              Cuando aparezca un óvalo, ajuste su rostro en un plazo de 7
              segundos.
            </li>
            <li>Suba el brillo para mayor claridad.</li>
            <li>Asegúrese de no tener gafas ni máscara puestos.</li>
            <li>Asegúrese de que no se refleje la luz del sol.</li>
          </ul>
        }
      />

      <Button
        type="primary"
        onClick={() => {
          setResults(null);
          setCreateLivenessApiData(null);
        }}
      >
        Reintentar
      </Button>
    </div>
  );

  const resultContent =
    results?.isLive && results.matchFaces
      ? successResult
      : results?.isLive && !results.matchFaces
      ? notMatchedResult
      : notLiveResult;

      const theme: Theme = {
        name: "Face Liveness Example Theme",
        tokens: {
          colors: {
            background: {
              primary: {
                value: tokens.colors.neutral["90"].value,
              },
              secondary: {
                value: tokens.colors.neutral["100"].value,
              },
            },
            font: {
              primary: {
                value: tokens.colors.white.value,
              },
            },
            brand: {
              primary: {
                "10": tokens.colors.teal["100"],
                "80": tokens.colors.teal["40"],
                "90": tokens.colors.teal["20"],
                "100": tokens.colors.teal["10"],
              },
            },
          },
        },
      };

  return (
    <ThemeProvider>
      {loading ? (
        <div style={{ height: 200 }}>
          <AppLoader />
        </div>
      ) : (
        <React.Fragment>
          {Boolean(results) ? (
            resultContent
          ) : (
            <div>
              <FaceLivenessDetector
                sessionId={createLivenessApiData?.sessionId || ""}
                region="us-east-1"
                onAnalysisComplete={handleAnalysisComplete}
                displayText={defaultLivenessDisplayText}
                onUserCancel={() => setCreateLivenessApiData(null)}
                onError={(error) => {
                  // setCreateLivenessApiData(null);
                }}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </ThemeProvider>
  );
}
